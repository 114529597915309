/** extend sidea */
/* stylelint-disable declaration-no-important */

:root {
    --font-p-mobile: 1.4rem;
    --line-height-p-mobile: 1.5;
    --font-weight-p-mobile: 400;
}

.CmsPage {
    @include mobile {
        margin-block-end: 0 !important;
    }

    &#chi-siamo {
        background-image: var(--background-acquerello);
        background-repeat: no-repeat;
        background-position: center -110px;
        margin-block-start: 45px;

        @include mobile {
            margin-block-start: 10px;
            background-position: 46% 20px;
            background-size: 190%;
        }

        .CmsPage-Content {
            @include mobile {
                margin-block-start: 0;
                padding-inline: 18px;

                h1.evidence {
                    font-size: 3rem;
                    line-height: 1.4;
                    padding: 0px 0;
                    margin-block-end: 30px !important;
                }

                h2.evidence {
                    margin-block-start: 0;
                    font-size: 2.4rem;
                    padding: 0 20px;
                }

                .h2-mod.h30 {
                    font-size: 1.6rem;
                    margin-block-start: 0;
                }

                .p24 p {
                    font-size: var(--font-p-mobile);
                    line-height: var(--line-height-p-mobile);
                    margin-block-end: 0;
                }

                .container {
                    padding: 0;

                    &.mb-9 {
                        margin-block-end: 40px !important;
                    }
                }

                .banner {
                    &.collect {
                        margin-inline-start: -18px;
                        width: 100%;
                    }
                }
            }
        }
    }

    &#click-and-collect {
        .banner-collect-page {
            margin-block-end: 170px;

            @include narrow-desktop {
                margin-block-end: 130px;
            }

            @include ultra-narrow-desktop {
                margin-block-end: 110px;
            }

            @include tablet {
                margin-block-end: 100px;
            }

            @include mobile {
                margin-block-end: 0;
                width: 100vw;
                margin-inline-start: -19px;
            }

            .pagebuilder-column-group {
                @include mobile {
                    flex-direction: column;

                    .pagebuilder-column {
                        width: 100% !important;
                    }
                }
            }
            
            figure {
                display: flex;
                justify-content: center;

                img {
                    width: 100%;
                    height: auto;

                    @include desktop-wide-1600 {
                        width: 100%;
                    }

                    @include ultra-narrow-desktop {
                        width: 100%;
                    }

                    @include tablet {
                        width: 100%;
                    }
                }
            }
        }

        .h2-mod.light {
            margin-block-end: 0;

            @include mobile {
                font-weight: 600;
                margin-block-start: 0;
                line-height: 1.5;
                font-size: 2.4rem;
            }
        }

        h4 {
            font-size: 2.1rem;
            font-weight: 600;
            margin-block-start: 0;
            margin-block-end: 15px !important;
        }

        @include mobile {
            .p16, .p16 p {
                font-size: 1.2rem;
                font-weight: var(--font-weight-p-mobile);
                line-height: 1.6 !important;
                margin-block-end: 0;

                &.cc-pw {
                    margin-block-end: 40px;
                }
            }
        }

        .text-cc {
            @include mobile {
                &.p16 {
                    margin-block-end: 0;
                }
            }
        }

        .list-steps {
            @include mobile {
                text-align: center;
                margin-block-end: 30px !important;
                max-width: 270px;

                .pagebuilder-column-group {
                    flex-direction: column;
                    gap: 10px;

                    .pagebuilder-column {
                        width: 100%;
                    }
                }
            }
            
            h2 {
                padding-inline-end: 5px;

                @include narrow-tablet {
                    position: absolute;
                    inset-inline-end: 43%;
                    z-index: 10;
                    font-size: 3.9rem;
                }

                @include mobile {
                    position: absolute;
                    inset-inline-end: 36%;
                    z-index: 10;
                    font-size: 3.9rem;
                }
            }

            figure {
                @include desktop {
                    position: absolute;
                    z-index: -1;
                    inset-inline-end: -39px;
                }

                @include mobile {
                    text-align: center;
                }

                img {
                    width: auto;
                    height: auto;

                    @include mobile {
                        width: auto;
                        margin-block-end: 0;
                    }
                }
            }

            .last-step {
                h2 {
                    @include mobile {
                        margin-block-start: 10px;
                    }
                }

                figure {
                    @include desktop {
                        inset-block-start: 20px;
                    }

                    @include narrow-desktop {
                        inset-block-start: 35px;
                    }
        
                    @include tablet {
                        inset-block-start: 35px;
                    }

                    @include narrow-tablet {
                        inset-block-start: 0;
                    }
                }
            }
        }
    }

    &#negozi,
    &#punto-vendita-campobasso-zuccarelli,
    &#punto-vendita-campobasso-colle-delle-api,
    &#punto-vendita-campobasso-pepe,
    &#punto-vendita-termoli,
    &#punto-vendita-isernia {
        @include desktop {
            background-image: var(--sfondo-negozi);
            background-repeat: no-repeat;
            background-position: left 100px;
        }

        @include mobile {
            background-image: var(--sfondo-negozi-mobile);
            background-repeat: no-repeat;
            background-position: left 20px;
        }

        .CmsPage-Content {
            margin-block-start: 56px;

            @include mobile {
                margin-block-start: 0;
                padding-inline: 18px;

                .container {
                    padding-inline-start: 0;
                    padding-inline-end: 0;
                }

                .mb-5 {
                    margin-block-end: 20px !important;
                }

                .mb-10 {
                    margin-block-end: 30px !important;
                }

                .mb-14 {
                    margin-block-end: 40px !important;
                }

                div:first-child + div {
                    h2:first-child {
                        font-size: 2.4rem;
                        text-align: start;
                        margin-block-start: 0;
                        font-weight: 600;
                    }
                }

                .pagebuilder-column-group {
                    flex-direction: column;

                    .pagebuilder-column {
                        width: 100%;

                        &.mappa-container {
                            iframe {
                                height: 270px;
                            }
                        }

                        &[data-pb-style=BQTWKCE],
                        &.mappa-container {
                            margin: 0 -18px;
                            width: calc(100% + 36px);
                        }

                        &[data-pb-style=VA3IIE3] {
                            padding-inline-start: 69px;
                            margin-block-start: 15px; 

                            p {
                                margin-block-end: 0;
                                font-weight: var(--font-weight-p-mobile);
                            }
                        }
                    }
                }
            
                .p24 p {
                    font-size: var(--font-p-mobile);
                    line-height: var(--line-height-p-mobile);
                    font-weight: var(--font-weight-p-mobile);
                    margin-block-end: 0;
                }

                .product-showcase {
                    margin-block-end: 40px !important;
                }

                .banner {
                    &.collect {
                        margin-inline-start: -18px;
                        width: 100%;
                    }
                }
            }
        }
    }

    .contatti-negozio {
        font-weight: 300;
        margin-block-start: 0;
        margin-block-end: 8px;

        @include mobile {
            text-align: start;
            font-size: 1.6rem;
        }

        @include ultra-narrow-desktop {
            font-size: 1.8rem;
        }

        @include tablet {
            font-size: 1.8rem;
        }

        @include narrow-tablet {
            font-size: 1.8rem;
        }

        &::before {
            content: '';
            margin-inline-start: 0;
            margin-inline-end: 15px;
            vertical-align: middle;
        }

        &.phone {
            &::before {
                content: var(--icon-phone);
            }            
        }

        &.email {
            &::before {
                content: var(--icon-mail);
            }            
        }
    }

    .orari-negozio {
        font-size: 2.4rem;
        width: fit-content;
        min-width: 45%;

        @include mobile {
            font-size: 1.6rem;
        }

        p {
            font-size: 2.4rem;
            margin-block-end: 6px;
            border-bottom: 1px solid var(--border-color-gray);

            @include ultra-narrow-desktop {
                font-size: 2rem;
            }

            @include tablet {
                font-size: 2rem;
            }

            @include narrow-tablet {
                font-size: 1.8rem;
            }

            @include mobile {
                font-size: 1.6rem;
                margin-block-end: 12px;
                padding-block-end: 5px;
                word-spacing: -2px;

                strong {
                    font-weight: 600;
                }
            }
        }
    }

    .slider-negozio {
        @include mobile {
            margin: 0 -18px;
            min-width: calc(100% + 36px);
        }

        .slick-dots {
            display: none !important;

            @include mobile {
                display: block !important;
                inset-block-end: 15px;

                li {
                    margin: 0 2px;
                }
            }
        }

        .slick-next, .slick-prev {
            @include mobile {
                display: none;
            }
        }
    }

    .mappa-container {
        iframe {
            @include desktop {
                height: 665px;
            }

            @include desktop-wide-1440 {
                height: 620px;
            }

            @include narrow-desktop {
                height: 555px;
            }

            @include ultra-narrow-desktop {
                height: 400px;
            }

            @include tablet {
                height: 400px;
            }
        }
    }

    &#termini-e-condizioni {
        padding-block-start: 60px;

        @include mobile {
            padding-block-start: 10px;  
        }

        .CmsPage-Heading {
            display: block;
        }

        .CmsPage-Content {
            padding-block-start: 30px;

            @include mobile {
                padding-block-start: 0; 
            }

            .container {
                max-width: 1180px;

                @include ultra-narrow-desktop {
                    max-width: 990px;
                }

                @include mobile {
                    max-width: 100%;
                }
            }

            h3 {
                @include mobile {
                    font-size: 18px;
                    margin-block-end: 10px;
                }
            }

            p {
                font-size: 1.4rem;

                @include mobile {
                    line-height: 1.5;
                }
            }

            .pagebuilder-button-link {
                line-height: 1.6;
            }

            .pagebuilder-column-group {
                @include mobile {
                    display: block;
                }

                .pagebuilder-column {
                    width: 100% !important; /* stylelint-disable-line */
                }
            }

            .Indice {
                display: flex;
                flex-wrap: wrap;

                &-Single {
                    flex: 0 0 50%;
                    line-height: 1.7;
                    font-size: 1.6rem;
                }
            }
        }
    }
}
