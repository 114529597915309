/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CarouselScroll {
    --translateY: 0;
    --animation-speed: 300ms;

    height: var(--carousel-height);
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block-start: var(--carousel-scroll-margin-top);
    width: fit-content;

    @include mobile {
        height: fit-content;
        --translateY: 0 !important; 
    }
    
    &-ContentWrapper {
        overflow: hidden;
        flex: 1;
        display: flex;
        width: var(--carousel-width);
    }

    &-Content {
        transform: translateY(var(--translateY));
        transition: transform var(--animation-speed);
        padding-inline-end: 1px;
        display: flex;
        flex-direction: row;
    }

    .CarouselScrollArrow {
        position: absolute;
        inset-inline-start: calc((100% - 25px) / 2);
        inset-block-start: -35px;
        transform: rotate(-90deg);

        &_isNextArrow {
            inset-block-end: -35px;
            inset-block-start: auto;
            transform: rotate(90deg);
        }
    }

    .ChevronIcon {
        width: 80%;
        height: 80%;
    }
}
