/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.AmastyOrderItem {
    .text-right {
        text-align: end;
        margin-block-start: 20px;
        color: #3A3939;
        font-size: 1.2rem;
        margin-inline-end: 20px;

        @include mobile {
            margin-block-start: 10px;
            margin-inline-end: 0;
        }
    }

    .amrma-checkbox-container {
        margin-inline-start: 20px;
        margin-block-start: 20px;

        @include mobile {
            margin-inline-start: 0;
            margin-block-start: 0;

            .Field {
                margin-block-start: -6px;
            }
        }
    }

    .amrma-product-information {
        display: flex;
        flex-direction: row;
        margin-inline-start: 40px;

        @include ultra-narrow-desktop {
            margin-inline-start: 25px;  
        }

        @include tablet {
            margin-inline-start: 25px;  
        }

        @include mobile {
            margin-inline-start: 20px;  
        }

        .information-block {
            margin-inline-start: 25px;
            max-width: 190px;
            margin-block-start: 10px;

            @include mobile {
                margin-inline-start: 0;
                max-width: unset;
                margin-block-start: 0;
            }

            .MyAccountOrderItemsTableRow-Brand {
                font-size: 2rem;
                font-weight: 600;
                color: #3A3939;
                font-family: $font-muli;
                text-transform: uppercase;

                @include mobile {
                    line-height: 1;
                    margin-block-end: 5px;
                }
            }

            p {
                color: #3A3939;
                font-size: 1.4rem;
                font-family: $font-base;
                font-weight: normal;
                margin-bottom: 0;

                @include mobile {
                    margin-block-end: 5px;
                }
            }

            .amrma-info {
                margin-block-end: 0;
            }
        }
    }

    &-Checkbox {
        input[type=checkbox] + .input-control {
            display: none;
        }

        .Field-CheckboxLabel {
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 1px solid #3a3939;
            cursor: pointer;
            position: relative;

            &::after {
                content: "";
                display: none;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #DF6F2C;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .Field-CheckboxLabel:has(input[type=checkbox]:checked) {
            border: 1px solid #DF6F2C;

            &::after { 
                display: block;
            }
          }
    }

    .MyAccountOrderItemsTableRow-Picture {
        margin-top: 16px;
        width: 94px;
        height: 94px;
        border: 1px solid #707070;
        padding-bottom: 94px;

        @include mobile {
            margin-block-start: 0;
            margin-inline-end: 25px;
            width: 80px;
            height: 80px;
            padding-bottom: 80px;
        }
    }
}