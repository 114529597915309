/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

.Slider {
    --translateX: 0;
    --translateY: 0;
    --animation-speed: 0;
    --slider-height: 600px;
    --slide-height: 0;
    --slide-width: 0;
    --height-transition-speed: 0;

    max-width: 100%;
    overflow: hidden;
    touch-action: pan-y;
    transition: height var(--height-transition-speed);
    
    &.homepage {
        margin-block-end: 20px;

        .Slider-Crumbs {
            position: absolute;
            width: auto;
            inset-inline-end: 28px;
            margin-block-start: 16px;
            justify-content: end;
            margin-block-end: 10px;
            grid-auto-flow: column;

            .Slider-Crumb {
                width: 6px;
                height: 6px;

                &_isActive {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    &.Brand {
        border: 1px solid #E3E3E3;
        padding-block: 47px;
        padding-inline: 10%;

        .Slider-Crumbs {
            display: none;
        }

        + .Slider-Arrow {
            padding-inline-end: 3%;
            padding-inline-start: 1%;
            width: 7%;
            height: calc(100% - 2px);
            background-color: #fff;

            &_isPrev {
                inset-inline-start: 1px;
            }

            .ChevronIcon {
                width: 6px;
            }

            + .Slider-Arrow {
                padding-inline-end: 1%;
                padding-inline-start: 3%;
                width: 7%;
                height: calc(100% - 2px);
                background-color: #fff;

                &_isNext {
                    inset-inline-end: 1px;
                }

                .ChevronIcon {
                    width: 6px;
                }
            }
        }
    }

    &-Wrapper {
        display: flex;
        align-items: flex-start;
        height: 100%;
        will-change: transform;
        transition: transform var(--animation-speed);
        transform: translate3d(var(--translateX), var(--translateY), 0);

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
        }

        &_isVertical {
            flex-direction: column;
        }

        &_slidesToDisplay_2 {
            > * {
                width: 50%;
            }
        }

        &_slidesToDisplay_3 {
            > * {
                width: 33.3333%;
            }
        }

        &_slidesToDisplay_4 {
            > * {
                width: 25%;

                @include mobile {
                    width: 50%;
                }
            }
        }

        &_slidesToDisplay_5 {
            > * {
                width: 20%;

                @include mobile {
                    width: 33.3333%;
                }
            }
        }

        &_slidesToDisplay_6 {
            > * {
                width: 16.6666%;

                @include mobile {
                    width: 33.3333%;
                }
            }
        }

        &_slidesToDisplay_2,
        &_slidesToDisplay_3,
        &_slidesToDisplay_4,
        &_slidesToDisplay_5,
        &_slidesToDisplay_6 {
            .SliderWidget {
                &-FigureImage {
                    display: block;
                    padding-block-end: 10%;
                    padding-block-start: 10%;
                    text-align: center;
            
                    @include mobile {
                        padding-block-end: 100%;
                    }
            
                    img {
                        object-fit: unset;
                        width: auto;
                        height: auto;
                        inset-inline: 0;
                        inset-block: 0;
                        margin: auto;
            
                        @include mobile {
                            object-position: center;
                        }
                    }
                }
            }
        }
    }

    &-Image {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-Counter {
        font-size: 1.2rem;
        color: var(--color-white);
        background: var(--color-dark-gray);
        border-radius: 15px;
        padding: 1px 4px 0;
        position: absolute;
        inset-block-end: 5px;
        inset-inline-end: 5px;
    }

    &-Crumbs {
        height: auto;
        min-height: 16px;
        position: absolute;
        margin: auto;
        display: grid;
        grid-gap: 4px;
        width: 100%;
        grid-template-columns: repeat(auto-fit, 16px);
        justify-content: center;
        inset-block-end: 12px;

        @include desktop {
            grid-gap: 16px;
        }
    }

    &-Crumb {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #efefef;
        will-change: width, height;
        transition: width 300ms, height 300ms;

        &_isActive {
            width: 16px;
            height: 16px;
            background-color: $default-primary-dark-color;
        }
    }

    &-Arrow {
        cursor: pointer;
        position: absolute;
        inset-block-start: 50%;
        width: 35px;
        height: 35px;

        .ChevronIcon {
            width: 13px;
            height: auto;
        }

        &_isPrev {
            inset-inline-start: -6%;

            &:dir(ltr) {
                transform: rotate(180deg) translateY(50%) scale(-1);
            }

            &:dir(rtl) {
                transform: translateY(50%);
            }
        }

        &_isNext {
            inset-inline-end: -6%;

            &:dir(ltr) {
                transform: translateY(-50%);
            }

            &:dir(rtl) {
                transform: rotate(180deg) translateY(-50%) scale(-1);
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: .5;
        }
    }
}

