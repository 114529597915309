/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

.Menu {
    &-Item {
        padding-inline-start: 0;
        margin-inline: 21px;

        @include desktop {
            height: auto;
        }

        @include narrow-desktop {
            margin-inline: 10px;
        }

        @include ultra-narrow-desktop {
            margin-inline-start: 10px;
            margin-inline-end: 5px;
        }

        @include tablet {
            margin-inline-start: 10px;
            margin-inline-end: 5px;
        }

        @include mobile {
            margin-inline: 0;

            &:last-child {
                margin-block-end: 6px;
            }
        }

        &Caption {
            color: var(--color-black);

            &:hover {
                color: var(--primary-base-color);
            }

            @include mobile {
                margin-inline: 0;
            }

            @include mobile {
                padding-block: 7px;
            }

            &_isSecondLevel {
                font-weight: 500;
            }

            &_type {
                &_main {
                    @include desktop {
                        margin: 0 0 0;
                    }

                    font-family: $font-muli;
                    text-transform: none;
                    font-size: 1.6rem;
                    font-weight: 500;
                    line-height: 1.4;
                    letter-spacing: 0.06rem;
                    padding-block-end: 30px;
                    padding-block-start: 57px;
                    white-space: nowrap;

                    @include narrow-desktop {
                        font-size: 1.4rem;
                    }

                    @include ultra-narrow-desktop {
                        font-size: 1.4rem;
                    }

                    @include tablet {
                        font-size: 1.4rem;
                        padding-block-start: 40px;
                        padding-block-end: 23px;
                    }

                    @include mobile {
                        text-transform: none;
                        line-height: 1.3;
                        font-weight: 700;
                        padding-block-end: 13px;
                        padding-block-start: 13px;
                    }
                }

                &_subcategory {
                    line-height: 20px;

                    @include mobile {
                        margin-inline-end: 0;
                    }

                    @include desktop {
                        margin-block-start: 12px;
                        display: inline-block;
                    }
                }
            }
        }

        &List {
            &_type_subcategory {
                @include mobile {
                    padding-block-start: 0;
                    padding-block-end: 10px;

                    .Menu-ExpandedState {
                        --plus-minus-icon-size: 21px;

                        inset-block-start: 2px;
                    }
                }
            }
        }

        &:not(:first-of-type) {
            @include desktop {
                margin-inline-start: 20px;
            }

            @include desktop-wide-1440 {    
                margin-inline-start: 15px;
            }

            @include narrow-desktop {
                margin-inline-start: 15px;
            }

            @include ultra-narrow-desktop {
                margin-inline-start: 15px;
            }

            @include tablet {
                margin-inline-start: 13px;
            }
        }

        &.Kids {
            @include narrow-desktop {
                margin-inline-start: 4px;
            }

            @include ultra-narrow-desktop {
                margin-inline-start: 4px;
            }

            @include tablet {
                margin-inline-start: 4px;
            }

            .Menu-ItemCaption_type_main {
                padding-block-end: 0;

                svg {
                    @include narrow-desktop {
                        transform: scale(0.9);
                    }

                    @include ultra-narrow-desktop {
                        transform: scale(0.8);
                    }

                    @include tablet {
                        transform: scale(0.8);
                    }
                }
            }
        }

        &.Promo {
            @include narrow-desktop {
                margin-inline-start: 4px;
            }

            @include ultra-narrow-desktop {
                margin-inline-start: 4px;
            }
            
            @include tablet {
                margin-inline-start: 4px;
            }

            .Menu-ItemCaption {
                @include narrow-desktop {
                    width: auto;
                    padding: 6px 10px;
                    border-radius: 10px;
                    margin-block-start: 53px;
                }

                @include ultra-narrow-desktop {
                    width: auto;
                    padding: 6px 10px;
                    border-radius: 10px;
                    margin-block-start: 53px;
                }
    
                @include tablet {
                    width: auto;
                    padding: 6px 10px;
                    border-radius: 10px;
                    margin-block-start: 34px;
                }
            }
        }
    }

    &-Link {
        cursor: pointer;

        @include ultra-narrow-desktop {
            font-size: 11px;
        }

        @include tablet {
            font-size: 12px;
        }

        &_isHovered {
            .Menu-ItemCaption_type_main {
                &::after {
                    @include desktop {
                        content: "";
                        display: block;
                        position: absolute;
                        inset-block-end: 0;
                        width: 100%;
                        height: 2px;
                        background-color: #FF8536;
                    }
                }
            }
        }
    }

    &-Sub {
        &Menu {
            @include mobile {
                margin-block-end: 35px;
            }

            &_isVisible {
                background: transparent;

                @include mobile {
                    margin-block-end: 0;
                    padding-inline-start: 20px;
                }
            }

            .Menu-ItemCaption {
                @include ultra-narrow-desktop {
                    padding-block-end: 2px;
                }

                @include tablet {
                    padding-block-end: 2px;
                }

                @include mobile {
                    padding-block: 4px;
                }
            }
        }
    }

    &-SubCategoriesWrapper {
        @include desktop {
            padding-inline: calc((100vw - var(--content-wrapper-large)) / 2);
            padding-inline-start: 28%;
            position: absolute;
            background: #fff;
            inset-block-start: 109px;
            inset-inline-start: 0;
            width: 100vw;
            z-index: 999;
            border-bottom: 1px solid $default-neutral-light-color;
        }

        @include narrow-desktop {
            inset-block-start: 107px;
        }

        @include ultra-narrow-desktop {
            inset-block-start: 107px;
        }

        @include tablet {
            inset-block-start: 107px;
        }
    }

    &-SubCategoriesWrapperInner {
        @include ultra-narrow-desktop {
            margin-inline-start: -16px;
        }

        @include tablet {
            margin-inline-start: -16px;
        }
    }

    &-SubCategories {
        padding: 25px 0 5px;
        text-transform: uppercase;

        @include ultra-narrow-desktop {
            padding: 5px 0;
        }
    }

    &-ExpandedState {
        --plus-minus-icon-size: 24px;

        inset-block-start: 2px;

        @include mobile {
            inset-block-start: 9px;
        }
    }

    &-RightButtonsWrapper {
        @include mobile {
            .Button {
                background-color: #fff;
                border: none;
                color: $black;
                display: block;
                font-size: 16px;
                line-height: 1.3;
                padding: 0;
                height: auto;
                letter-spacing: 0.06rem;
                padding-block: 7px;
                margin-block-end: 6px;

                &.newProducts {
                    color: $default-secondary-base-color;
                }

                &.sales {
                    color: $default-primary-base-color;
                }
            }
        }
    }

    @include mobile {
        &-Additional {
            font-family: $font-muli;
            margin-block-start: 25px;
            display: flex;
            flex-direction: column;
            row-gap: 23px;
            font-size: 1.6rem;
            font-weight: 600;
            padding-inline: 43px;
    
            .Menu-Button {
                font-family: $font-muli;
                font-size: 1.6rem;
                font-weight: 600;

                svg {
                    vertical-align: top;
                    margin-inline-end: 15px;
                }

                &.Wishlist {
                    svg {
                        transform: scale(0.9);
                        margin-inline-start: -5px;
                        margin-inline-end: 10px;
                    }
                }
            }

            .Header-StoreButtonWrapper a::before {
                margin-block-start: 0;
                margin-inline-end: 13px;
            }
        }
    }
}

.MenuPage {
    @include desktop {
        display: none;
    }
    
    @include mobile {
        inset-block-start: 0;
        inset-block-end: 0;
        position: fixed;
        margin-block: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100vw;
        min-height: unset;
        padding-block-start: 25px;
        padding-inline: 0;
        background-color: $white;
        z-index: 999;
        transform: translateX(-100%);
        transition: all 200ms;
    }

    &.isVisible {
        transform: translateX(0);
    }

    &#MenuMobile {
        .Logo {
            text-align: center;
            margin-block-end: 15px;
            height: auto;
    
            @include desktop {
                display: none;
            }
    
            img {
                @include mobile {
                    position: relative;
                    max-width: 127px;
                }
            }
        }
    }

    &-Close {
        @include mobile {
            position: fixed;
            inset-block-start: 20px;
            inset-inline-start: 25px;
            inset-inline-end: auto;
            width: 50px !important; /* stylelint-disable-line declaration-no-important */
            height: 50px;
            text-align: center;
            background-color: #fff;
            z-index: 9999;
            display: none;

            svg {
                vertical-align: middle;
            }

            &.isVisible {
                display: block;
            }
        }

        @include desktop {
            display: none;
        }

        @include tablet {
            display: none;
        }
    }

    .Menu-MainCategories {
        border-bottom: 1px solid #DEDEDE;
        padding-inline: 43px;
        padding-block-end: 6px;
    }
}

@include mobile {
    #conveythis {
        margin-block-end: 30px;
        margin-inline-start: -10px;
    }
}

.Footer-Content {
    .Menu-SubCategoriesWrapper {
        display: none;
    }
}

