/** override sidea */

$white: #fff;
$default-primary-base-color:  #F26700;
$default-primary-dark-color: #FF8536;
$default-primary-light-color: #FFB383;
$default-primary-extralight-color: #F5FFEF;
$default-secondary-base-color: #1E3E27;
$default-secondary-dark-color: #2C673C;
$default-secondary-light-color: #ACD8A0;
$default-neutral-base-color: #3A3939;
$default-neutral-dark-color: #9B9B9B;
$default-neutral-light-color: #DEDEDE;
$default-neutral-extralight-color: #EFEFEF;
$default-neutral-thin-color: #F5F5F5;
$default-button-disabled: #C1C1C1;
$black: #272727;
$font-muli: 'Oswald', sans-serif;
$font-base: 'Roboto', sans-serif;
$font-body-size: 1.6rem;
$font-standard-size: 62.5%;
