/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** extends and override sidea */

.Footer {
    --footer-background-color: #fff;

    $column-count: 4;

    @include mobile {
        margin-block-end: 0;
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--footer-background-color);
 
        .Prefooter {
            &-BlockWrapper {
                border-bottom: 1px solid #DEDEDE;
                padding-block-end: 40px;

                @include mobile {
                    padding-block-end: 0;
                    border-bottom: none;
                }

                .ContentWrapper {
                    display: flex;
                    align-items: flex-end;
                    padding-inline: 8%;

                    @include ultra-narrow-desktop {
                        padding-inline: 3%;
                    }
            
                    @include tablet {
                        padding-inline: 3%;
                    }
                    
                    @include mobile {
                        flex-direction: column;
                        row-gap: 10px;
                        padding-inline: 0;
                    }
                    
                    .Image.Logo {
                        overflow: visible;
                        width: auto;
                        height: auto;
                        padding-inline-end: 0;
                        margin-inline-end: 14%;
                        margin-block-end: 22px;

                        @include narrow-desktop {
                            max-width: 180px;
                            margin-inline-end: 10%;
                        }

                        @include ultra-narrow-desktop {
                            max-width: 180px;
                            margin-inline-end: 10%;
                        }

                        @include tablet {
                            max-width: 160px;
                            margin-inline-end: 10%;
                            margin-block-end: 13px;
                        }

                        @include mobile {
                            max-width: 178px;
                            padding-inline-start: 0;
                            padding-inline-end: 0;
                            margin-block-end: 0;
                            margin: 0 auto !important;
                        }

                        .Logo-Placeholder {
                            position: relative;
                        }

                        img {
                            position: relative;
                        }
                    }
                }
            }
        }
    }

    &-Columns {
        padding-inline: 8%;

        @include ultra-narrow-desktop {
            padding-inline: 3%;
        }

        @include tablet {
            padding-inline: 3%;
        }

        @include mobile {
            display: block;
            text-align: start;
            padding: 20px 18px;
            padding-inline: 0;
        }
    }

    &-Column {
        @include narrow-tablet {
            width: 33% !important; /* stylelint-disable-line declaration-no-important */
            
            &:last-child {
                display: none;
            }
        }

        @include mobile {
            display: inline-block;
            width: 50%;
        }

        &:not(:last-of-type) {
            @include desktop {
                padding-inline-end: 80px;
                max-width: calc(100% / #{ $column-count - 1 });
            }

            @include desktop-wide-1440 {
                padding-inline-end: 20px;
            }

            @include narrow-desktop {
                padding-inline-end: 20px;
            }

            @include tablet {
                padding-inline-end: 20px;
            }

            @include mobile {
                margin-block-end: 20px;
            }
        }

        &:nth-child(4) {
            @include mobile {
                width: 100%;
                
                .Footer-ColumnContent {
                    flex-direction: row;
                    flex-wrap: wrap;
                    
                    a {
                        flex: 1 1 40%;
                    }
                }
            }
        }

        &.InfoAddress {
            font-family: $font-muli;
            margin-inline-end: 11%;

            @include desktop-wide-1440 {
                margin-inline-end: 5%;
            }

            @include narrow-desktop {
                margin-inline-end: 5%;
            }
            
            @include ultra-narrow-desktop {
                margin-inline-end: 0;
                padding-inline-end: 40px;
            }

            @include tablet {
                margin-inline-end: 0;
                padding-inline-end: 40px;
            }

            @include mobile {
                width: 100%;
                margin-inline-end: 0;
            }

            p {
                font-family: $font-muli;
                font-size: 1.2rem;
            }

            .CmsBlock-Wrapper {
                @include tablet {
                    width: 200px;
                }

                @include mobile {
                    div {
                        div {
                            flex-direction: row !important;
                            justify-content: space-between !important;
                        }
                    }
                }
            }

            .Social-box {
                display: flex;
                align-items: flex-start;
                margin-block-start: 30px;

                @include mobile {
                    margin-block-start: 0;
                    margin-block-end: 5px;
                    justify-content: flex-end;
                    padding-inline: 60px;
                }

                div {
                    margin-inline-end: 15px;

                    img {
                        height: 22px;
                        width: auto;
                    }
                }
            }
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include ultra-narrow-desktop {
            gap: 8px;
        }

        @include tablet {
            gap: 5px;
        }

        @include mobile {
            gap: 3px;
        }
    }

    &-ColumnItem {
        border-radius: 0;
        color: $black;
        margin-block-end: 0;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 22px;
        white-space: nowrap;

        &:hover {
            color: var(--link-hover);
            text-decoration: none;
        }

        @include mobile {
            white-space: normal;
        }
    }

    &-LastColumn {
        padding-inline-start: 8%;

        @include desktop-wide-1440 {
            padding-inline-start: 4%;
        }

        @include narrow-desktop {
            padding-inline-start: 4%;
            width: 30%;
        }

        @include ultra-narrow-desktop {
            padding-inline-start: 4%;
            width: 30%;
            min-width: 200px;
        }

        @include tablet {
            padding-inline-start: 0;
            min-width: 200px;
        }

        @include mobile {
            padding-inline-start: 0;
        }

        .Footer-ColumnTitle {
            width: 50%;
            display: inline-block;
        }

        .phone {
            display: flex;

            &::before {
                content: var(--icon-phone);
                margin-inline-end: 8px;

                @include mobile {
                    vertical-align: top;
                }
            } 
        }

        .phone, .phone a {
            font-family: $font-base;
            font-size: 2.4rem;
            font-weight: 500;
            margin-block-end: 18px;
            margin-block-start: 0;
            text-decoration: none;

            @include ultra-narrow-desktop {
                font-size: 2rem;
            }

            @include tablet {
                font-size: 1.8rem;
            }

            @include mobile {
                width: 50%;
                display: inline-block;
                margin-block-end: 0;
                font-size: 1.8rem;
                font-weight: 500;
            }           
        }

        .p14 {
            br {
                display: none;
            }
        }

        .CmsBlock-Wrapper {
            div {
                div {
                    @include mobile {
                        display: block !important;  /* stylelint-disable-line declaration-no-important */
                    }

                    .p14 {
                        @include desktop-wide-1440 {
                            font-size: 1.2rem;
                        }
                
                        @include narrow-desktop {
                            font-size: 1.2rem;
                        }

                        @include ultra-narrow-desktop {
                            font-size: 1.2rem;
                        }

                        @include tablet {
                            font-size: 1rem;
                        }
                    }

                    .p12 {
                        @include mobile {
                            line-height: 1.5;
                        }

                        & br {
                            @include narrow-desktop {
                                display: none;
                            }

                            @include mobile {
                                display: none;
                            }
                        }
                    }
                }
            }

            .image-nofull, .image-nofull img {
                @include ultra-narrow-desktop {
                    width: 100%;
                    height: auto;
                }

                @include tablet {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .link-primary,
        a {
            font-size: 12px;
            font-weight: 600;
            text-decoration: underline;

            @include mobile {
                font-size: 1.4rem;
            }

            &:hover {
                color: $black;
            }
        }

        .shopping-sicuro {
            &::after {
                content: var(--loghi-secure);
                display: block;
                margin-block-start: 3px;
            }
        }
    }

    &-ColumnTitle {
        white-space: nowrap;
        font-weight: 700;

        @include mobile {
            font-size: 1.6rem;
            margin-block-end: 12px;
        }
    }

    &::after {
        display: block;
        content: var(--background-green);
        width: 100%;
        overflow: hidden;

        @include narrow-desktop {
            max-height: 200px;
        }

        @include ultra-narrow-desktop {
            max-height: 200px;
        }

        @include tablet {
            max-height: 160px;
        }

        @include mobile {
            display: none;
        }
    }

    &-CopyrightContentWrapper {
        background-color: transparent;
        padding-inline: 8%;
        padding-block-end: 80px;
        margin-block-start: -41px;

        @include ultra-narrow-desktop {
            padding-inline: 3%;
            padding-block-end: 50px;
        }

        @include tablet {
            padding-inline: 3%;
            padding-block-end: 50px;
        }

        @include mobile {
            padding-inline: 0;
            padding-block-end: 40px;
            margin-block-start: 0;
        }
    }

    &-CopyrightContent {
        padding: 0;
        justify-content: flex-start;
        font-size: 12px;

        @include tablet {
            font-size: 11px;
        }

        @include mobile {
            padding: 0px 18px 18px;
            flex-direction: column;
            row-gap: 7px;
            font-size: 12px;
        }

        .Footer-Copyright {
            color: $black;
            padding-inline: 0;

            @include tablet {
                font-size: 11px;
            }

            @include mobile {
                font-size: 12px;
            }
        }

        a {
            font-size: 12px;
            font-weight: 400;
            margin-inline-end: 10px;

            @include tablet {
                font-size: 11px;
            }
            
            @include mobile {
                font-size: 12px;
            }
        }

        .Footer-PrivacyLinks {
            text-transform: uppercase;
            margin-inline-start: 30px;

            a {
                &:first-child {
                    &::after {
                        content: "|";
                        font-size: 10px;
                        margin-inline-start: 10px;
                    }
                }
            }
        }
    }

    &.CheckoutPage {
        .Newsletter-NewsletterBlockWrapper {
            display: none;
        }

        .Footer-CopyrightContentWrapper {
            @include desktop-wide-1440 {
                padding-inline-start: 32px;
                max-width: 1350px;
                padding-inline-end: 32px;
                padding-block-end: 60px;
                margin-block-start: 0px;
            }

            @include narrow-desktop {
                padding-inline-start: 32px;
                max-width: 1350px;
                padding-inline-end: 32px;
                padding-block-end: 40px;
                margin-block-start: 0px;
            }

            @include tablet {
                padding-inline-start: 32px;
                max-width: 100%;
                padding-inline-end: 32px;
                padding-block-end: 30px;
                margin-block-start: 40px;
            }
        }
    }
}
