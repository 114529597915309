/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ImageZoomPopup {
    height: 100%;

    
    &.Popup {
        height: 100%;
    }
    
    &-PopupContent.Popup-Content {
        max-height: 100vh;
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        padding: 0;


        .Popup-CloseBtn {
            inset-inline-end: 10px;
            inset-block-start: 10px;
        }
    }

    .ProductGallery {
        height: 100%;
        width: 100%;

        &-SliderWrapper {
            height: 100%;

            @include ultra-narrow-desktop {
                max-height: unset;
            }

            @include tablet {
                max-height: unset;
            }

            @include narrow-tablet {
                max-height: unset;
            }
        }

        &-Additional {
            .CarouselScroll {  
                @include ultra-narrow-desktop {
                    position: absolute;
                    inset-block-start: -70vh;
                    max-height: unset;
                    z-index: 9999999999;
                }  

                @include tablet {
                    position: absolute;
                    inset-block-start: -70vh;
                    max-height: unset;
                    z-index: 9999999999;
                }

                @include narrow-tablet {
                    position: absolute;
                    inset-block-start: -70vh;
                    max-height: unset;
                    z-index: 9999999999;
                }
            }
        }
    }

    .Slider-Arrow {
        display: none;
    }
}
