/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.SocialLoginButtons {
    width: 100%;
    margin-block-start: 40px;

    &-Heading {
        margin-block-end: 0px;
        border-block-start: 1px solid #DEDEDE;

        h4 {
            font-size: 2.1rem;
            font-weight: 600;
            color: #3A3939;
            margin: 0px;
            background-color: #fff;
            inset-block-start: -15px;
            width: fit-content;
            margin-left: 31.5%;
        }
    }

    &-Row {
        display: flex;
        justify-content: center;
        margin-inline-start: 10px;
    }

    &-Column {
        margin-inline-end: 13px;
    }
}

.Checkout {
    .SocialLoginButtons {
        margin-block-start: 50px;
        margin-block-end: 40px;

        &-Heading {
            display: flex;
            justify-content: center;

            h4 {
                margin-inline-start: 0px;
            }
        }
    }
}
