@font-face {
    font-family: 'slick';
    font-weight: 400;
    font-style: normal;
    src: url('fonts/slick.eot');
    src: url('fonts/slick.eot?#iefix') format('embedded-opentype'), url('fonts/slick.woff') format('woff'), url('fonts/slick.ttf') format('truetype'), url('fonts/slick.svg#slick') format('svg')
}

.slick-dots,
.slick-next,
.slick-prev {
    position: absolute;
    display: block;
    padding: 0
}

.slick-dots li button::before,
.slick-next::before,
.slick-prev::before {
    font-family: 'slick', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-loading .slick-list {
    background: url('ajax-loader.gif') center center no-repeat #fff
}

.slick-next,
.slick-prev {
    font-size: 0;
    line-height: 0;
    inset-block-start: 43%;
    width: 30px;
    height: 45px;
    -webkit-transform: translate(0, -50%) scale(0.6);
    -ms-transform: translate(0, -50%) scale(0.6);
    transform: translate(0, -50%) scale(0.6);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0
}

.slick-next:focus::before,
.slick-next:hover::before,
.slick-prev:focus::before,
.slick-prev:hover::before {
    opacity: 1
}

.slick-next.slick-disabled::before,
.slick-prev.slick-disabled::before {
    opacity: .25
}

.slick-next::before,
.slick-prev::before {
    font-size: 20px;
    line-height: 1;
    opacity: 1;
    color: #000;
}

.slick-prev {
    inset-inline-start: -35px;

    @include desktop-wide-1440 {
        inset-inline-start: -30px; 
    }

    @include tablet {
        inset-inline-start: -25px; 
    }
}

[dir=rtl] .slick-prev {
    inset-inline-end: -35px;
    inset-inline-start: auto;

    @include desktop-wide-1440 {
        inset-inline-start: -30px; 
    }

    @include tablet {
        inset-inline-start: -25px; 
    }
}

.slick-prev::before {
    content: url('images/arrow-prev.svg');
}

.slick-next::before,
[dir=rtl] .slick-prev::before {
    content: url('images/arrow-next.svg');
}

.slick-next {
    inset-inline-end: -35px;

    @include desktop-wide-1440 {
        inset-inline-end: -30px; 
    }

    @include tablet {
        inset-inline-end: -25px; 
    }
}

[dir=rtl] .slick-next {
    inset-inline-end: auto;
    inset-inline-start: -35px;

    @include desktop-wide-1440 {
        inset-inline-end: -30px; 
    }
    
    @include tablet {
        inset-inline-end: -25px; 
    }
}

[dir=rtl] .slick-next::before {
    content: url('images/arrow-next.svg');
}

.slick-dotted.slick-slider {
    margin-block-end: 30px
}

.slick-dots {
    inset-block-end: -25px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0
}

.slick-dots li button:focus,
.slick-dots li button:hover {
    outline: 0
}

.slick-dots li button:focus::before,
.slick-dots li button:hover::before {
    opacity: 1
}

.slick-dots li button::before {
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #000;
}

.slick-dots li.slick-active button::before {
    opacity: .75;
    color: #000;
}
