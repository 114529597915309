/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: #000;
    --search-field-background: #000;
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: #000;
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #E3E3E3;
}

.SearchField {
    width: 100%;
    margin-block-end: 23px;
    
    @include desktop {
        max-width: var(--search-bar-max-width);
    }

    @include tablet {
        max-width: 330px;
        margin-block-end: 16px;
    }
    
    &-SearchInnerWrapper {
        @include tablet {
            padding-inline-end: 8px;
        }
    }

    &-Input {
        border: none;
        border-bottom: 1px solid $default-neutral-base-color;
        height: 35px;
        font-size: 12px;
        font-weight: 400;
        padding-block-start: 30px;

        @include desktop {
            min-width: 207px;
        }

        @include desktop-wide-1600 {
            min-width: 180px;
        }

        @include desktop-wide-1440 {
            min-width: 130px;
            max-width: 105px;
        }

        @include ultra-narrow-desktop {
            min-width: 130px;
            max-width: 105px;
            padding-block-start: 25px;
            padding-inline-start: 15px;
        }

        @include tablet {
            min-width: 0;
            max-width: 0;
            border-bottom: none;
            background-color: transparent;
            padding-block-start: 25px;
            padding-inline-start: 20px;

            &_isActive {
                width: 180px;
                max-width: 180px;
                border-bottom: 1px solid $default-neutral-base-color;
            }
        }

        @include mobile {
            border-bottom: 1px solid #3A3939;
            height: 33px;
            padding: 4px 12px 0 30px;
            font-size: 1.4rem;
        }

        &:focus {
            border: none;
            border-bottom: 1px solid $default-primary-base-color;
        }

        &::placeholder {
            color: #fff;
            display: none;

            @include mobile {
                color: #272727;
                display: block;
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        inset-block-start: 32%;
        inset-inline-end: auto;

        @include ultra-narrow-desktop {
            inset-block-start: 27%;

            svg {
                width: 20px;
            }
        }

        @include tablet {
            inset-block-start: 27%;
        }

        @include mobile {
            inset-block-start: 15%;
            
            svg {
                width: 24px; 
            }
        }
    }

    &-SearchIcon {
        @include tablet {
            z-index: -1;
        }
    }

    &-CloseIcon {
        height: 20px;
        width: 20px;

        svg {
            width: 17px;

            @include ultra-narrow-desktop {
                width: 13px;
            }
        }
    }
}
