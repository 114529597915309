/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Component Sidea - Popup selezione pickup store in pagina dettaglio prodotto */

.StorePickup-Popup {
    justify-content: flex-end;
    width: 100vw;
    height: 100vh;
    inset-block-start: 0;
    transition: transform 200ms ease-in-out;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 1;

    &-EmptyResult {
        margin-block-start: 10px;
    }

    .Popup {
        &-Content {
            background-color: #fff;
            border-radius: 0;
            min-width: 410px;
            overflow-x: hidden;
            max-height: unset;
            position: relative;
            z-index: 10;
            width: 410px;
            padding: 43px;
            height: 100%;
            overflow-y: scroll;
            
            svg {
                stroke: none;
            }

            @include mobile {
                width: 100%;
                min-width: unset;
                padding: 25px 40px 40px;
            }

            h2 {
                @include mobile {
                    font-size: 3rem;
                    margin-block-start: 15px;
                    margin-block-end: 12px;
                }
            }
        }

        &-CloseBtn {
            position: relative;
            inset-block-start: 0;
            inset-inline-end: auto;
            inset-inline-start: 0;
            z-index: 1;
        }

        &-Header {
            padding-block-start: 0;
            margin-block-end: 60px;

            @include mobile {
                margin-block-end: 10px;
            }
        }
    }

    &-InputHeading {
        margin-block-start: 60px;
        font-size: 1.6rem;
        color: #000000;

        @include mobile {
            margin-block-start: 30px;
        }
    }

    &-Input {
        margin-block-start: 0;

        input {
            height: 50px;
            border: 1px solid #3A3939;
            width: 100%;
        }
    }

    &-Icon {
        position: absolute;
        inset-block-start: 55px;
        inset-inline-start: 89%;

        @include mobile {
            inset-block-start: 51px;
            inset-inline-start: calc(100% - 37px);
        }
    }

    &_isVisible {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateY(0%);
        transition: transform 200ms ease-in-out;
    }

    .StorePickup {
        &-List {
            font-weight: 700;
            font-size: 1.4rem;
            margin-block-start: 45px;

            @include mobile {
                margin-block-start: 35px;
            }
        }

        &-SingleStore {
            padding-block-end: 35px;
            margin-block-end: 25px;
            border-bottom: 1px solid #DEDEDE;

            .SingleStore {
                &-Info {
                    border: none;
                    padding: 0 0 20px;
                    padding-inline-start: 45px;
                    margin-block-end: 0;

                    @include mobile {
                        padding-inline-start: 40px; 
                    }
                }
    
                &-Orari {
                    font-size: 12px;
                    font-weight: 400;
                    margin-block-end: 0;

                    .Orari-Button {
                        font-weight: 400;
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    .SingleStore-Description {
                        max-height: 0;
                        -webkit-transition: max-height 0.5s; 
                        -moz-transition: max-height 0.5s; 
                        -ms-transition: max-height 0.5s; 
                        -o-transition: max-height 0.5s; 
                        transition: max-height 0.5s; 
                        overflow-y: hidden;

                        div {
                            p:first-child {
                                margin-block-start: 8px;
                            }

                            p {
                                font-size: 1.2rem;
                            }
                        }
                    }

                    &:hover {
                        .SingleStore-Description {
                            max-height: 150px;
                            overflow-y: visible;
                        } 
                    }
                }
            }

            &.CollectExpressAvailable {
                .SingleStore {
                    &-Info {
                        border: none;
                    }
                }

                .Button.primary-black.small {
                    background-color: var(--button-background);
                    border-color: var(--button-background);

                    &:hover {
                        background-color: var(--button-hover-background);
                        border-color: var(--button-hover-background);
                    }
                }
            }
        }

        &-Selection {
            &.Button {
                width: 100%;
                max-width: 310px;
                height: 50px;
                font-size: 2rem;

                @include mobile {
                    max-width: 288px;
                    height: 40px;
                    font-size: 1.6rem;
                }
            }
        }

        &-Name {
            font-size: 12px;
            font-weight: 400;
            margin-block-end: 0;
            display: none;
        }

        &-Address {
            font-family: $font-muli;
            font-size: 1.6rem;
            font-weight: 600;

            span.City {
                text-transform: uppercase;

                &::after {
                    content: ",";
                }
            }

            svg {
                height: 21px;
                width: 17px;
                vertical-align: middle;
                position: absolute;
                inset-inline-start: -30px;
                margin-inline-end: 13px;

                @include mobile {
                    inset-inline-start: -36px;
                }
            }
        }

        &-CollectExpress {
            color: $default-primary-base-color;
            margin-block-end: 5px;

            p {
                font-weight: bold;
                font-size: 1.6rem;
                margin-block-end: 0;
                color: $default-primary-base-color;
            }

            .CollectExpress {
                &-Note {
                    color: $black;
                }
            }

            svg {
                vertical-align: middle;
                position: absolute;
                inset-inline-start: -30px;
                margin-inline-end: 13px;

                @include mobile {
                    inset-inline-start: -36px;
                }
            }
        }
    }

    .SingleStore-Selection {
        p {
            @include mobile {
                font-size: 1.5rem;
            }

            strong {
                display: block;
  
                @include mobile {
                    margin-block-end: 5px;
                }
            }
        }
        
        .ResetSelection {
            cursor: pointer;
            color: #9b9b9b;

            .CloseIcon {
                width: 15px;
                height: 13px;
                vertical-align: middle;
                margin-inline-end: 5px;
            }

            &:hover {
                color: $black;

                .CloseIcon {
                    stroke: $black;
                }
            }
        }
    }
}
