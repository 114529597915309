/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ReturnGuestForm {
    width: 100%;
    margin-block-start: 100px;

    @include desktop-wide-1440 {
        margin-block-start: 70px;
    }

    @include narrow-desktop {
        margin-block-start: 50px;
    }

    @include tablet {
        margin-block-start: 50px;
    }

    @include mobile {
        margin-block-start: 20px;
    }

    label {
        display: none;
    }

    .FieldForm {
        &-Fields {
            .col-md-6 {
                @include mobile {
                    padding-inline: 0;

                    &:first-child {
                        .Field-Wrapper_type_select {
                            margin-block-start: 40px;
                        }
                    }
                }
            }
        }
    }

    .Field {
        margin-block-start: 0;
    }

    .FieldSelect .ChevronIcon {
        inset-inline-end: 22px;
        inset-block-start: 24px;
    }

    .Field-Wrapper {
        margin-block-end: 25px;

        @include mobile {
            margin-block-end: 10px;
        }
    }

    input, select {
        height: 60px;
        margin: 0;
        font-family: $font-base;
        font-size: 1.6rem;

        @include mobile {
            font-size: 1.4rem;
        }
    }

    .Field-Wrapper.Field-Wrapper_type_text:has(input[name="guest_returns"]) {
        display: none;
    }

    input[name="guest_returns"] {
        display: none;
    }

    textarea {
        width: 100%;
        height: 400px;
        font-family: $font-base;
        font-size: 1.6rem;
        line-height: 1.4;

        @include mobile {
            font-size: 1.4rem;
            height: 200px;
        }
    }

    &-ActionBlock {
        margin-block-start: 25px;

        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .Field-CheckboxLabel {
        .label-text {
            font-size: 1.2rem;
        }
    }

    .Button {
        width: 100%;
        margin-block-start: 20px;

        @include tablet {
            margin: 0 auto;
            display: block;
        }
    }

    .Field_type_checkbox {
        .input-control {
            width: 40px;
            align-self: flex-start;
        }
    }
}
