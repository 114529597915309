/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContactForm {
    &-Heading {
        margin-block-end: 47px;

        @include mobile {
            margin-block-end: 27px; 
        }

        h2 {
            font-size: 4.2rem;
            line-height: 1.4;
            color: #3A3939;
            font-weight: bold;
            max-width: 469px;
            margin-block-start: 60px;

            @include desktop-wide-1600 {
                line-height: 1.2;
                margin-block-start: 40px;
            }

            @include narrow-desktop {
                font-size: 3.2rem;
            }

            @include ultra-narrow-desktop {
                font-size: 2.8rem;
            }

            @include tablet {
                font-size: 2.6rem;
            }

            @include mobile {
                font-size: 3rem;
                margin-block-end: 0;
            }
        }
    }

    &-Number, &-Email {
        line-height: 1.6;

        svg {
            margin-inline-end: 20px;
        }

        font-family: $font-muli;
        color: #3A3939;
        font-weight: normal !important;
        font-size: 2.4rem;

        a {
            font-weight: normal;
        }

        @include narrow-desktop {
            font-size: 2rem;
        }

        @include ultra-narrow-desktop {
            font-size: 1.8rem;
        }

        @include tablet {
            font-size: 1.8rem;
        }

        @include mobile {
            font-size: 1.6rem;
            margin-block-end: 20px;
        }
    }

    &-Email {
        svg {
            @include mobile {
                vertical-align: middle;
            }
        }
    }

    textarea {
        width: 100%;
        border: 1px solid #CCCCCC;
        min-height: 138px;
    }

    .Field-Wrapper_type_textarea {
        min-height: 138px;
        margin-block-start: 50px;
        margin-block-end: 20px;

        @include tablet {
            margin-block-start: 24px;
        }
    }

    .Field-Wrapper {
        width: 100%;
        max-width: 720px;
        height: 60px;

        @include narrow-tablet {
            max-width: 100%;
        }
    }

    .Field [type='checkbox'] + .input-control {
        border: 1px solid #666666;
    }

    .Field.Field_type_checkbox {
        font-size: 1.2rem;
        color: #3A3939;
    }

    .bottom-form {
        margin-block-start: 25px;
        max-width: 450px;
    }

    .p-0 {
        padding: 0;
    }

    .input-control {
        width: 48px !important; /* stylelint-disable-line declaration-no-important */
    }

    .Field-CheckboxLabel {
        align-items: center;
    }

    .Button {
        width: 294px;
        height: 50px;

        @include ultra-narrow-desktop {
            width: 100%;
        }

        @include tablet {
            width: 100%;
        }
    }

    input, textarea, select {
        border: 1px solid #3A3939;
    }

    select {
        height: 60px;
    }

    .Field-Wrapper:first-of-type .Field {
        margin-block-start: 0;
    }

    label {
        display: none;
    }
}
