/** override sidea */

:root {
    // h1 desktop
    --h1-font-size: var(--imported_h1_font_size, 4.2rem);
    --h1-font-weight: var(--imported_h1_font_weight, 700);
    --h1-color: var(--imported_h1_color, #{$black});
    --h1-line-height: var(--imported_h1_line_height, 1);
    --h1-text-transform: var(--imported_h1_text_transform, none);
    --h1-font-style: var(--imported_h1_font_style, normal);

    //h1 desktop wide (max 1440px)
    --h1-font-size-wide: 3.8rem;
    --h1-line-height-wide: 1;

    // h1 narrow desktop
    --h1-font-size-narrow: 3.5rem;
    --h1-line-height-narrow: 1;

    // h1 tablet
    --h1-font-size-tablet: 3rem;
    --h1-line-height-tablet: 1;
    
    // h1 mobile
    --h1-font-weight-mobile: var(--imported_h1Mobile_font_weight, 700);
    --h1-font-size-mobile: var(--imported_h1Mobile_font_size, 2.7rem);
    --h1-color-mobile: var(--imported_h1Mobile_color, #{$black});
    --h1-line-height-mobile: var(--imported_h1Mobile_line_height, 1.1);
    --h1-text-transform-mobile: var(--imported_h1Mobile_text_transform, none);
    --h1-font-style-mobile: var(--imported_h1Mobile_font_style, normal);

    // h2 desktop
    --h2-font-size: var(--imported_h2_font_size, 3rem);
    --h2-font-weight: var(--imported_h2_font_weight, 700);
    --h2-color: var(--imported_h2_color, #{$black});
    --h2-line-height: var(--imported_h2_line_height, 1.2);
    --h2-text-transform: var(--imported_h2_text_transform, normal);
    --h2-font-style: var(--imported_h2_font_style, normal);

    //h2 desktop wide (max 1440px)
    --h2-font-size-wide: 2.8rem;
    --h2-line-height-wide: 1.2;

    // h2 narrow desktop
    --h2-font-size-narrow: 2.6rem;
    --h2-line-height-narrow: 1.2;

    // h2 tablet
    --h2-font-size-tablet: 2rem;
    --h2-line-height-tablet: 1;

    // h2 mobile
    --h2-font-size-mobile: var(--imported_h2Mobile_font_size, 2rem);
    --h2-font-weight-mobile: var(--imported_h2Mobile_font_weight, 700);
    --h2-color-mobile: var(--imported_h2Mobile_color, #{$black});
    --h2-line-height-mobile: var(--imported_h2Mobile_line_height, 1.3);
    --h2-text-transform-mobile: var(--imported_h2Mobile_text_transform, normal);
    --h2-font-style-mobile: var(--imported_h2Mobile_font_style, normal);

    // h3 desktop
    --h3-font-size: var(--imported_h3_font_size, 2.4rem);
    --h3-font-weight: var(--imported_h3_font_weight, 400);
    --h3-color: var(--imported_h3_color, #{$black});
    --h3-line-height: var(--imported_h3_line_height, 1.3);
    --h3-text-transform: var(--imported_h3_text_transform, normal);
    --h3-font-style: var(--imported_h3_font_style, normal);

    // h3 narrow desktop
    --h3-font-size-narrow: 2rem;
    --h3-line-height-narrow: 1.3;

    // h3 mobile
    --h3-font-size-mobile: var(--imported_h3Mobile_font_size, 1.8rem);
    --h3-font-weight-mobile: var(--imported_h3Mobile_font_weight, 400);
    --h3-color-mobile: var(--imported_h3Mobile_color, #{$black});
    --h3-line-height-mobile: var(--imported_h3Mobile_line_height, 1.5);
    --h3-text-transform-mobile: var(--imported_h3Mobile_text_transform, normal);
    --h3-font-style-mobile: var(--imported_h3Mobile_font_style, normal);

    // h4 desktop
    --h4-font-size: var(--imported_h3_font_size, 2.1rem);
    --h4-font-weight: var(--imported_h3_font_weight, 600);
    --h4-color: var(--imported_h3_color, #{$black});
    --h4-line-height: var(--imported_h3_line_height, 1.3);
    --h4-text-transform: var(--imported_h3_text_transform, normal);
    --h4-font-style: var(--imported_h3_font_style, normal);

    // h4 narrow desktop
    --h4-font-size-narrow: 1.8rem;
    --h4-line-height-narrow: 1.3;

    // h4 mobile
    --h4-font-size-mobile: var(--imported_h3Mobile_font_size, 1.7rem);
    --h4-font-weight-mobile: var(--imported_h3Mobile_font_weight, 600);
    --h4-color-mobile: var(--imported_h3Mobile_color, #{$black});
    --h4-line-height-mobile: var(--imported_h3Mobile_line_height, 1.5);
    --h4-text-transform-mobile: var(--imported_h3Mobile_text_transform, normal);
    --h4-font-style-mobile: var(--imported_h3Mobile_font_style, normal);

    // h5 desktop
    --h5-font-size: var(--imported_h3_font_size, 1.6rem);
    --h5-font-weight: var(--imported_h3_font_weight, 600);
    --h5-color: var(--imported_h3_color, #{$black});
    --h5-line-height: var(--imported_h3_line_height, 1.3);
    --h5-text-transform: var(--imported_h3_text_transform, normal);
    --h5-font-style: var(--imported_h3_font_style, normal);

    // h5 narrow desktop
    --h5-font-size-narrow: 1.4rem;
    --h5-line-height-narrow: 1.3;

    // h5 mobile
    --h5-font-size-mobile: var(--imported_h3Mobile_font_size, 1.6rem);
    --h5-font-weight-mobile: var(--imported_h3Mobile_font_weight, 600);
    --h5-color-mobile: var(--imported_h3Mobile_color, #{$black});
    --h5-line-height-mobile: var(--imported_h3Mobile_line_height, 1.5);
    --h5-text-transform-mobile: var(--imported_h3Mobile_text_transform, normal);
    --h5-font-style-mobile: var(--imported_h3Mobile_font_style, normal);

    // paragraph
    --paragraph-font-size: var(--imported_paragraph_font_size, 1.6rem);
    --paragraph-font-weight: var(--imported_paragraph_font_weight, 400);
    --paragraph-color: var(--imported_paragraph_color, #{$black});
    --paragraph-line-height: var(--imported_paragraph_line_height, 1.56);
    --paragraph-text-transform: var(--imported_paragraph_text_transform, none);
    --paragraph-font-style: var(--imported_paragraph_font_style, normal);

    // paragraph mobile
    --paragraph-font-size-mobile: var(--imported_paragraphMobile_font_size, 14px);
    --paragraph-font-weight-mobile: var(--imported_paragraphMobile_font_weight, 400);
    --paragraph-color-mobile: var(--imported_paragraphMobile_color, #{$black});
    --paragraph-line-height-mobile: var(--imported_paragraphMobile_line_height, 1.2);
    --paragraph-text-transform-mobile: var(--imported_paragraphMobile_text_transform, none);
    --paragraph-font-style-mobile: var(--imported_paragraphMobile_font_style, normal);

    // caption
    --caption-font-size: var(--imported_caption_font_size, 12px);
    --caption-font-weight: var(--imported_caption_font_weight, 400);
    --caption-color: var(--imported_caption_color, #{$black});
    --caption-line-height: var(--imported_caption_line_height, 20px);
    --caption-text-transform: var(--imported_caption_text_transform, none);
    --caption-font-style: var(--imported_caption_font_style, normal);

    // caption mobile
    --caption-font-size-mobile: var(--imported_captionMobile_font_size, 12px);
    --caption-font-weight-mobile: var(--imported_captionMobile_font_weight, 400);
    --caption-color-mobile: var(--imported_captionMobile_color, #{$black});
    --caption-line-height-mobile: var(--imported_captionMobile_line_height, 16px);
    --caption-text-transform-mobile: var(--imported_captionMobile_text_transform, none);
    --caption-font-style-mobile: var(--imported_captionMobile_font_style, normal);
}

* {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    outline: 0;
    text-shadow: none;
    // backface-visibility: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;

    @media print {
        /* stylelint-disable-next-line declaration-no-important */
        background: transparent !important;
        /* stylelint-disable-next-line declaration-no-important */
        color: #000000 !important;
        /* stylelint-disable-next-line declaration-no-important */
        filter: none !important;
        /* stylelint-disable-next-line declaration-no-important */
        text-shadow: none !important;
    }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
label,
summary {
    display: block;
}

header,
footer,
main {
    font-size: 12px;

    @include mobile {
        font-size: 14px;
    }
}

main {
    --border-width: 1px;

    min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
    max-width: 100vw;

    @include mobile {
        min-height: calc(100vh - var(--header-total-height));
        margin-block-end: var(--footer-total-height);
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    margin-block-end: 14px;

    @include mobile {
        margin-block-end: 17px;
    }
}

img {
    width: 100%;
}

html {
    font-size: $font-standard-size;
}

body {
    font-family: $font-base;
    font-size: $font-body-size;
    color: $black;
    scroll-behavior: smooth;
    background-color: #fff;
}

h1, h2, h3, h4, h5 {
    font-family: $font-muli;
}

a {
    color: $black;
    text-decoration: inherit;

    &:hover {
        cursor: pointer;
    }
}

.pagebuilder-mobile-hidden {
    @include mobile {
        display: none;
    }
}

.pagebuilder-mobile-only {
    @include desktop {
        display: none;
    }
}

h1 {
    color: var(--h1-color);
    font-size: var(--h1-font-size);
    font-style: var(--h1-font-style);
    font-weight: var(--h1-font-weight);
    line-height: var(--h1-line-height);
    text-transform: var(--h1-text-transform);

    @include desktop-wide-1440 {
        font-size: var(--h1-font-size-wide);
        line-height: var(--h1-line-height-wide);
    }

    @include narrow-desktop {
        font-size: var(--h1-font-size-narrow);
        line-height: var(--h1-line-height-narrow);
    }

    @include mobile {
        color: var(--h1-color-mobile);
        font-size: var(--h1-font-size-mobile);
        font-style: var(--h1-font-style-mobile);
        font-weight: var(--h1-font-weight-mobile);
        line-height: var(--h1-line-height-mobile);
        text-transform: var(--h1-text-transform-mobile);
    }

    &.light {
        font-weight: 300;
    }

    + h2,
    + h3,
    + h4 {
        margin-block-start: 0px;

        @include mobile {
            margin-block-start: 0px;
        }
    }
}

h2 {
    color: var(--h2-color);
    font-weight: var(--h2-font-weight);
    font-size: var(--h2-font-size);
    font-style: var(--h2-font-style);
    line-height: var(--h2-line-height);
    margin-block-end: 18px;
    margin-block-start: 22px;
    text-transform: var(--h2-text-transform);

    @include desktop-wide-1440 {
        font-size: var(--h2-font-size-wide);
        line-height: var(--h2-line-height-wide);
    }

    @include narrow-desktop {
        font-size: var(--h2-font-size-narrow);
        line-height: var(--h2-line-height-narrow);
    }

    @include tablet {
        font-size: var(--h2-font-size-tablet);
        line-height: var(--h2-line-height-tablet);
    }

    @include mobile {
        color: var(--h2-color-mobile);
        font-size: var(--h2-font-size-mobile);
        font-style: var(--h2-font-style-mobile);
        font-weight: var(--h2-font-weight-mobile);
        line-height: var(--h2-line-height-mobile);
        margin-block-end: 21px;
        margin-block-start: 25px;
        text-transform: var(--h2-text-transform-mobile);
    }

    + h3,
    + h4 {
        inset-block-start: 0px;
        margin-block-start: 5px;
        margin-block-end: 0px;

        @include mobile {
            inset-block-start: 0px;
            margin-block-start: 5px;
            margin-block-end: 0px;
        }
    }
}

h3 {
    color: var(--h3-color);
    font-size: var(--h3-font-size);
    font-style: var(--h3-font-style);
    font-weight: var(--h3-font-weight);
    line-height: var(--h3-line-height);
    margin-block-start: 22px;
    margin-block-end: 18px;
    text-transform: var(--h3-text-transform);

    @include narrow-desktop {
        font-size: var(--h3-font-size-narrow);
        line-height: var(--h3-line-height-narrow);
    }

    @include mobile {
        color: var(--h3-color-mobile);
        font-size: var(--h3-font-size-mobile);
        font-style: var(--h3-font-style-mobile);
        font-weight: var(--h3-font-weight-mobile);
        line-height: var(--h3-line-height-mobile);
        margin-block-start: 25px;
        margin-block-end: 21px;
        text-transform: var(--h3-text-transform-mobile);
    }

    + h4 {
        inset-block-start: 0;
        margin-block-start: 5px;
        margin-block-end: 0;

        @include mobile {
            inset-block-start: 0;
            margin-block-start: 5px;
            margin-block-end: 0;
        }
    }
}

h4 {
    color: var(--h4-color);
    font-size: var(--h4-font-size);
    font-style: var(--h4-font-style);
    font-weight: var(--h4-font-weight);
    line-height: var(--h4-line-height);
    margin-block-start: 20px;
    margin-block-end: 16px;
    text-transform: var(--h4-text-transform);

    @include narrow-desktop {
        font-size: var(--h4-font-size-narrow);
        line-height: var(--h4-line-height-narrow);
    }

    @include mobile {
        color: var(--h4-color-mobile);
        font-size: var(--h4-font-size-mobile);
        font-style: var(--h4-font-style-mobile);
        font-weight: var(--h4-font-weight-mobile);
        line-height: var(--h4-line-height-mobile);
        margin-block-start: 20px;
        margin-block-end: 20px;
        text-transform: var(--h4-text-transform-mobile);
    }
}

h5 {
    color: var(--h5-color);
    font-size: var(--h5-font-size);
    font-style: var(--h5-font-style);
    font-weight: var(--h5-font-weight);
    line-height: var(--h5-line-height);
    margin-block-start: 15px;
    margin-block-end: 10px;
    text-transform: var(--h5-text-transform);

    @include narrow-desktop {
        font-size: var(--h5-font-size-narrow);
        line-height: var(--h5-line-height-narrow);
    }

    @include mobile {
        color: var(--h5-color-mobile);
        font-size: var(--h5-font-size-mobile);
        font-style: var(--h5-font-style-mobile);
        font-weight: var(--h5-font-weight-mobile);
        line-height: var(--h5-line-height-mobile);
        margin-block-start: 15px;
        margin-block-end: 10px;
        text-transform: var(--h5-text-transform-mobile);
    }
}


h6 {
    font-size: 16px;
    text-transform: none;
    font-weight: 600;
    line-height: 1.5;
}

p,
li,
dl {
    color: var(--paragraph-color);
    font-size: var(--paragraph-font-size);
    font-style: var(--paragraph-font-style);
    font-weight: var(--paragraph-font-weight);
    line-height: var(--paragraph-line-height);
    text-transform: var(--paragraph-text-transform);

    @include mobile {
        color: var(--paragraph-color-mobile);
        font-size: var(--paragraph-font-size-mobile);
        font-style: var(--paragraph-font-style-mobile);
        font-weight: var(--paragraph-font-weight-mobile);
        line-height: var(--paragraph-line-height-mobile);
        text-transform: var(--paragraph-text-transform-mobile);
    }
}

li {
    list-style-type: none;
    list-style-position: inside;
    margin-block-end: 6px;

    // override default blue color for lists in Safari since iOS15

    & * {
        color: var(--color-black);
    }

    ol & {
        counter-increment: list;

        @include mobile {
            counter-increment: list;
        }
    }

    &:last-child {
        margin-block-end: 14px;
    }
}

p {
    margin-block-end: 14px;

    @include mobile {
        margin-block-end: 17px;
        word-break: break-word;
    }

    &.caption {
        color: var(--caption-color);
        font-size: var(--caption-font-size);
        font-style: var(--caption-font-style);
        font-weight: var(--caption-font-weight);
        line-height: var(--caption-line-height);
        text-transform: var(--caption-text-transform);

        @include mobile {
            color: var(--caption-color-mobile);
            font-size: var(--caption-font-size-mobile);
            font-style: var(--caption-font-style-mobile);
            font-weight: var(--caption-font-weight-mobile);
            line-height: var(--caption-line-height-mobile);
            text-transform: var(--caption-text-transform-mobile);
        }
    }
}

input,
select {
    min-height: 24px;
    min-width: 48px;
    font-size: 14px;

    @include mobile {
        min-height: 28px;
        min-width: 56px;
        font-size: 14px;
    }
}

textarea {
    resize: none;

    @include mobile {
        font-size: 16px;
        width: 100%;
    }
}

mark {
    margin-inline-end: 5px;
}

iframe {
    max-width: 100%;
}

a,
input,
button,
textarea,
select,
details {
    @include mobile {
        touch-action: manipulation;
    }
}
