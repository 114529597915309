/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContactPage {
    @include mobile {
        border-bottom: 1px solid #DEDEDE;
        padding-block-end: 40px;
        margin-block-end: 0;
    }

    &-Heading {
        height: fit-content;
        margin-block-start: 50px;
        margin-block-end: 50px;
        font-size: 7.2rem;
        font-weight: 300;

        @include desktop-wide-1600 {
            font-size: 6.5rem;
        }
    
        @include desktop-wide-1440 {
            font-size: 6rem;
            margin-inline-start: -50px;
        }
    
        @include narrow-desktop {
            font-size: 5.8rem;
        }
    
        @include ultra-narrow-desktop {
            font-size: 4.5rem;
        }
    
        @include tablet {
            font-size: 4.5rem;
            margin-block-start: 35px;
        }
    
        @include narrow-tablet {
            font-size: 4rem;
        }

        @include mobile {
            margin-block-start: 10px;
            margin-block-end: 20px;
            font-size: 2.7rem;
        }

        strong {
            font-weight: 600;
            line-height: 1.2;
        }
    }

    &-ColumnWrapper {
        display: flex;
        margin-block-start: 50px;
        margin-block-end: 125px;
        
        @include ultra-narrow-desktop {
            margin-block-end: -50px;
        }

        @include tablet {
            margin-block-end: -50px;
        }

        @include mobile {
            display: block;
            padding: 12px 0 0;
            margin-inline-start: 0;
            margin-block-end: 0;
            margin-block-start: 10px;
        }
    }

    &-Column {
        flex: 1;

        &_isContent {
            margin-inline-end: 75px;
            overflow: hidden;

            @include desktop-wide-1440 {
                margin-inline-end: 50px;
            }

            @include narrow-desktop {
                margin-inline-end: 50px;
            }

            @include ultra-narrow-desktop {
                margin-inline-end: 30px;
            }

            @include tablet {
                margin-inline-end: 30px;
            }

            .CmsBlock-Wrapper {
                @include desktop-wide-1600 {
                    height: 94%;
                }

                @include narrow-desktop {
                    height: 92%;
                }

                @include ultra-narrow-desktop {
                    height: 87%;
                }

                @include tablet {
                    height: 87%;
                }

                div {
                    height: 100%;
                }

                figure {
                    height: 100%;

                    @include desktop-wide-1600 {
                        margin-inline-start: -10%;
                    }

                    @include desktop-wide-1440 {
                        margin-inline-start: -25%;
                    }

                    @include narrow-desktop {
                        margin-inline-start: -35%;
                    }

                    @include ultra-narrow-desktop {
                        margin-inline-start: -50%;
                    }

                    @include tablet {
                        margin-inline-start: -50%;
                    }

                    img {
                        width: auto;

                        @include mobile {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }

            @include mobile {
                margin-inline-end: 0;
                margin-block-end: 20px;
                width: 100vw;
                margin-inline-start: -19px;
            }
        }
    }

    .CmsBlock-Wrapper p {
        font-size: 3.3rem;
        font-weight: 300;
        line-height: 1.2;

        @include ultra-narrow-desktop {
            font-size: 2.8rem;
        }

        @include tablet {
            font-size: 2.8rem;
        }

        @include mobile {
            font-size: 2.4rem;
            margin-block-end: 25px;
        }

        strong {
            font-weight: bold;
        }
    }

    .Form {
        @include mobile {
            .col-md-6 {
                padding-inline-start: 0;
                padding-inline-end: 0;
                margin-block-end: 10px;
            }
    
            .Field {
                margin-block-start: 10px;
            }

            .FieldSelect-Select {
                margin-block-start: 0;
            }
        }

        .Button {
            @include mobile {
                width: 198px;
                max-width: 100%;
                height: 40px;
                margin-block-start: 30px;
                margin-inline-start: calc((100% - 198px) / 2);
            }
        }
    }
}
