/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --expandable-content-color: var(--color-karaka);
    --expandable-content-divider-color: var(--primary-divider-color);
    --toggle-button-size: 18px;
}

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
    margin-block-start: 20px;

    @include mobile {
        margin-block-start: 7px;
    }
}

.ExpandableContent {
    @include mobile {
        border-block-start: none;

        &:last-of-type {
            border-block-end: none;
        }

        .AddIcon,
        .MinusIcon,
        .ChevronIcon {
            width: var(--toggle-button-size);
            height: var(--toggle-button-size);
        }

        .AddIcon,
        .MinusIcon {
            width: 21px;
            height: 21px;
        }
    }

    &-Button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: 0;
        cursor: pointer;

        @include mobile {
            padding-block: 14px;
        }
    }

    &-Heading,
    &-SubHeading {
        display: block;
        color: var(--expandable-content-color);
    }

    &-Heading {
        font-family: $font-muli;
        line-height: 20px;
        word-break: break-all;

        @include mobile {
            font-size: 14px;
        }

        @include desktop {
            cursor: pointer;
            font-size: 1.6rem;
            font-weight: 600;
            text-transform: none;
        }
    }

    &-SubHeading {
        font-weight: 400;
        font-size: 10px;
        line-height: 1.1;

        @include mobile {
            font-size: 12px;
        }
    }

    &-Content {
        transition-property: opacity;
        transition-duration: 500ms;

        @include mobile {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }

        @include desktop {
            margin-block-start: 0;
        }

        &_isContentExpanded {
            @include expanded-content;

            @include mobile {
                @include expanded-content;
            }
        }

        .ProductAttributeValue-String {
            border-radius: 5px;
            min-width: 35px;
            padding: 0;
            font-weight: 500;
            border-color: $default-neutral-dark-color;
        }
    }

    @include mobile {
        &_isWithoutBorder {
            border: 0;

            &:last-of-type {
                border: 0;
            }
        }
    }
}
