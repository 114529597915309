/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

.Breadcrumb {
    $crumb-padding: 20px;

    display: inline-block;
    vertical-align: top;
    font-size: 4.2rem;
    line-height: 60px;
    padding-block-start: 5px;
    border-right: 1px solid #DEDEDE;
    padding-inline-end: $crumb-padding;
    margin-inline-end: $crumb-padding;

    @include ultra-narrow-desktop {
        padding-inline-end: 15px;
        margin-inline-end: 15px;
        font-size: 2.8rem;
        padding-block-start: 3px;
        line-height: 50px;
    }

    @include tablet {
        padding-inline-end: 15px;
        margin-inline-end: 15px;
        font-size: 2.7rem;
        padding-block-start: 3px;
        line-height: 50px;
    }

    @include mobile {
        font-size: 2rem;
        line-height: 20px;
        padding-block-start: 7px;
        padding-inline-end: 12px;
        margin-inline-end: 12px;
    }

    h1 {
        font-weight: 300;
        line-height: 60px;

        @include ultra-narrow-desktop {
            font-size: 2.8rem;
            line-height: 50px;
        }

        @include tablet {
            font-size: 2.7rem;
            line-height: 50px;
        }

        @include mobile {
            font-size: 2rem;
            line-height: 20px;
        }
    }

    .ChevronIcon {
        filter: invert(57%) sepia(0%) saturate(1%) hue-rotate(105deg) brightness(93%) contrast(90%);
        display: inline-block;
        margin-inline-start: $crumb-padding;
        width: 10px;
        height: 8px;
        inset-block-end: 0;

        @include mobile {
            margin-inline-start: 5px;
        }
    }

    &:last-child {
        margin-block-end: 0;
        border-right: none;

        a {
            color: --breadcrumbs-active-color;
        }

        .ChevronIcon {
            display: none;
        }

        :link {
            pointer-events: none;
        }
    }

    &::before {
        content: none;
    }

    &-Link {
        color: var(--breadcrumbs-color);
        display: inline-block;
        font-weight: 300;
        font-size: 4.2rem;

        @include ultra-narrow-desktop {
            font-size: 3.4rem;
        }

        @include tablet {
            font-size: 3.2rem;
        }

        @include mobile {
            color: #DEDEDE;
            font-size: 2rem;
        }

        a {
            color: var(--breadcrumbs-color)
        }

        &:hover {
            color: var(--breadcrumbs-active-color);
        }

        &-Name {
            color: $default-neutral-light-color;

            &:hover {
                color: var(--breadcrumbs-active-color);
            }
        }
    }
}
