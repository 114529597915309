/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* funziona, il compilatore non è aggiornato per :has() */
.Breadcrumbs {
    &:has(+ .Router-MainItems .MyAccount) {
      display: none;
    }
}

.AmastyMyReturnsPage {
    width: 100%;
    margin: auto;

    .HeadingNewRequest {
        margin: 0;
        margin-bottom: 55px;
        margin-top: 55px;
        font-size: 4.2rem;
        font-weight: bold;
        color: #F26700;
        border-bottom: 1px solid #707070;
        padding-bottom: 13px;
        padding-inline-start: 10px;

        @include desktop-wide-1440 {
            font-size: 3.5rem;
        }

        @include narrow-desktop {
            font-size: var(--h1-font-size-narrow);
            line-height: var(--h1-line-height-narrow);
        }

        @include ultra-narrow-desktop {
            font-size: 2.6rem;
        }

        @include tablet {
            font-size: 2.1rem;
        }

        @include mobile {
            font-size: 2.1rem;
            margin-bottom: 10px;
            margin-top: 45px;
            border-block-end: none;
            padding-inline-start: 0;
        }
    }

    .amreturns-order-select {
        display: flex;
        align-items: baseline;

        @include mobile {
            flex-direction: column;
            gap: 15px;
            margin-block-end: 35px;
        }

        .amreturns-title-container {
            margin: 0 15px 0 20px;

            @include tablet {
                margin: 0 15px 0 0;
            }

            @include mobile {
                margin: 0;
            }

            .amreturns-select-title {
                font-weight: 600;
                line-height: 24px;
                color: #3A3939;
                font-size: 2.1rem;
                font-family: $font-muli;

                @include ultra-narrow-desktop {
                    font-size: 1.7rem;
                }

                @include tablet {
                    font-size: 1.6rem;
                }

                @include mobile {
                    font-size: 1.6rem;
                }
            }
        }

        .amreturns-orders-container {
            flex-grow: 1;
            display: flex;
            align-items: baseline;

            .Field-Wrapper {
                flex-grow: 1;
                width: 100%;
                max-width: 487px;

                .Field {
                    margin-top: 0;
                }

                select {
                    height: 60px;

                    @include mobile {
                        height: 55px;
                    }
                }
            }

            .amreturn-btn {
                margin-inline-start: 30px;
                width: 100%;
                max-width: 217px;

                @include ultra-narrow-desktop {
                    max-width: 150px;
                }

                @include tablet {
                    max-width: 150px;
                }

                @include mobile {
                    max-width: unset;
                }

                @include mobile {
                    margin-inline-start: 20px;
                    max-width: 100px;
                    height: 50px;
                    line-height: 50px;
                }
            }
        }
    }

    .table-caption {
        display: none;
    }

    .amrma-table-container {
        margin-block-end: 75px;
        border: 1px solid #707070;
        padding: 36px 22px 45px 30px;

        @include mobile {
            border: none;
            margin-block-end: 25px;
            padding: 0;
        }
    }

    .table-wrapper {
        border-top: none;
        overflow-x: auto;
    }

    .amrma-table {
        border-collapse: collapse;
        border-spacing: 0;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-style: normal;
        line-height: 1.42857143;
        margin-bottom: 0;
        width: 100%;

        .amrma-status {
            padding: 5px 10px 1px;
            border-radius: 4px;

            @include mobile {
                padding-inline-start: 0;
            }
        }
    }

    table td, table th {
        min-width: unset;
    }

    table tbody tr {
        border-bottom: 1px solid #DEDEDE !important;

        @include mobile {
            &.amrma-row {
                border: 1px solid #707070 !important;
                padding: 15px 18px;
                margin-block-end: 10px;
                display: flex;
                flex-direction: column;
            }
        }
    }

    .amrma-table thead {
        color: #333333;
        font-size: 1.4rem;
        border-bottom: 1px solid #DEDEDE;
    }

    .amrma-table thead tr th,
    .amrma-table .amrma-tbody tr td {
        background-color: #fff;
        font-size: 1.6rem;
        color: #3A3939;
        text-align: center;
        padding-inline: 0;

        @include desktop-wide-1440 {
            font-size: 1.5rem;
        }

        @include ultra-narrow-desktop {
            font-size: 1.4rem;
        }

        @include tablet {
            font-size: 1.4rem;
        }

        @include mobile {
            font-size: 1.4rem;
            text-align: start;
            padding-block: 1px;
        }
    }
    
    .amrma-table thead tr th {
        font-family: $font-muli;
        font-weight: 600;

        @include mobile {
            display: none;
        }
    }

    .amrma-table .amrma-tbody tr td {
        font-family: $font-base;
        color: #000000;
        vertical-align: middle;

        @include tablet {
            font-size: 1.3rem;
        }

        @include mobile {
            font-family: $font-muli;
            font-weight: 300;
            font-size: 1.4rem;
            width: 100%;
        }

        span.orderLabel {
            display: none;
            font-weight: 600;

            @include mobile {
                display: inline-block;
                min-width: 30%;
            }
        }
    }

    .col.actions {
        font-size: 1.2rem !important;
        text-decoration: underline;
        font-weight: normal;

        @include mobile {
            font-size: 1.4rem !important;
            text-align: end !important;
        }
    }

    .col.id {
        width: 12%;
    }

    .col.status {
        width: 20%;
    }

    .returns-history > .amrma-table .amrma-tbody > .amrma-row .amrma-image {
        width: 50px;
        height: 60px;
    }

    .message.info {
        margin: 10px 0 20px;
        padding: 12px 20px;
        display: block;
        font-size: 1.6rem;
        background: #fdf0d5;
        color: #6f4400;
        position: relative;
    }
}

