/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* override Sidea */
/* stylelint-disable font-family-no-missing-generic-family-keyword */

.FidelityForm {
    .ContentWrapper {
        padding-block-end: 50px;
        padding-inline: 20px;

        @supports (-webkit-touch-callout: none) {
            padding-block-end: 80px;
        }

        @include mobile {
            padding-inline: 0;
        }
    }

    .fid-container {
        margin-inline: 23%;
    }

    &-Actions {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-block-start: -100px;
    }

    &-InnerWrapper {
        @include desktop() {
            display: flex;
            max-width: 1241px;
            width: 100%;
            margin: auto;
        }

        .MyAccountOverlay {
            &-Legend {
                h3 {
                    margin-block-end: 20px;
                    font-size: 1.6rem;
                    font-weight: 500;
        
                    @include mobile {
                        margin-block-start: 15px;
                        margin-block-end: 15px;
                    }
                }
        
                .Field {
                    margin-block-start: 0px;
                    margin-block-end: 10px;
        
                    input {
                        border: 1px solid #707070;
                    }
                }
            }
        
            &-Additional {
                display: none;
            }

            &-PersonalInfoLegend {
                padding-block: 8px;
                text-align: start;
            }
        
            &-SignUpLegend {
                padding-block: 28px 8px;
                text-align: start;
        
                @include narrow-desktop {
                    padding-block-start: 0;
                }
        
                @include tablet {
                    padding-block-start: 0;
                }
        
                @include narrow-tablet {
                    padding-block-start: 0;
                }
        
                @include mobile {
                    padding-block: 15px 0px;
                }
            }
        
            &-SignUpButton {
                width: 294px;
                height: 50px;
        
                @include mobile {
                    font-size: 1.4rem;
                    margin-block-end: 0;
                    width: 100%;
                    max-width: 400px;
                    height: 43px;
                }
            }
        
            &-SignInButton {
                height: 50px;
            }
        
            &-PasswordBlock {
                @include wide-desktop {
                    display: block;
                }
        
                .pass-bar {
                    margin-block-start: 5px;
                    margin-block-end: 10px;
                }
            }
        
            &-ConsentBlock {
                max-width: 485px;
                width: 100%;
        
                h3 {
                    @include tablet {
                        font-size: 2rem; 
                    }
        
                    @include mobile {
                        margin-block-end: 15px;
                    }
                }
        
                @include mobile {
                    max-width: unset;
        
                    .p14,
                    .p14 p {
                        font-size: 1.2rem;
                        line-height: 1.5;
                        margin-block-end: 20px !important; /* stylelint-disable-line declaration-no-important */
                    }
        
                    a {
                        font-size: 1.2rem;
                        text-decoration: underline;
                    }
                }
            }
        
            &-BlockCreate {
                display: flex;
                flex-direction: row;
                margin-inline-start: 13%;
        
                @include mobile {
                    flex-direction: column;
                }
        
                input {
                    max-width: 460px;
                    width: 100%;
        
                    @include narrow-tablet {
                        max-width: 100%;
                    }
                }

                .informazioni {
                    width: 41%;

                    @include narrow-desktop {
                        width: 50%;
                    }
        
                    @include tablet {
                        width: 50%;
                    }
        
                    @include narrow-tablet {
                        width: 50%;
                    }
        
                    @include mobile {
                        width: 100%;
                    }
                }
        
                .privacy {
                    margin-inline-start: 10%;
        
                    @include narrow-desktop {
                        margin-inline-start: 5%;
                    }
        
                    @include tablet {
                        margin-inline-start: 5%;
                    }
        
                    @include narrow-tablet {
                        margin-inline-start: 5%;
                    }
        
                    @include mobile {
                        margin-inline-start: 0;
                        width: 100%;
                        margin-block-start: 40px;
                    }
                }
            }
        
            &-ConsentCheck {
                .Field {
                    &.Field_type_checkbox label:first-of-type {
                        display: block;
        
                        .label-text {
                            display: inline-block;
                            width: calc(100% - 30px);
        
                            @include mobile {
                                width: calc(100% - 40px); 
                            }
                        }
                    }
        
                    input {
                        &[type=checkbox] {
                            padding: 0;
                            border: 1px solid #fff;
                            border-radius: 50%;
        
                            + .input-control {
                                border-radius: 12px;
                                display: inline-block;
                                vertical-align: top;
        
                                &::after {
                                    box-shadow: none;
                                    content: '';
                                    height: 8px;
                                    width: 8px;
                                    inset-block-start: 4px;
                                    inset-inline-start: 4px;
                                    transform: none;
                                    border-radius: 10px;
                                    transition: none;
                                }
                            }
        
                            &:hover:not(:disabled) + .input-control {
                                --box-color: #fff;
        
                                border: 1px solid $default-primary-dark-color;
                                margin-inline-end: 11px;
                            }
                        }
                    }
                }
        
                .Field-CheckboxLabel {
                    align-items: flex-start;
                }
        
                .Field-CheckboxLabel span, .Field.Field_type_checkbox {
                    display: block;
                    font-size: 1.2rem;
                    font-weight: 300;
                }
            }
        
            &-Buttons {
                margin-block-start: 35px;
                
                @include narrow-desktop {
                    text-align: center;
                }
        
                @include tablet {
                    text-align: center;
                }
        
                @include narrow-tablet {
                    text-align: center;
                }
        
                .MyAccountOverlay-BackLogin {
                    @include narrow-desktop {
                        display: block;
                        margin-inline-start: 0;
                        margin-block-start: 20px;
                    }
            
                    @include tablet {
                        display: block;
                        margin-inline-start: 0;
                        margin-block-start: 20px;
                    }
            
                    @include narrow-tablet {
                        display: block;
                        margin-inline-start: 0;
                        margin-block-start: 20px;
                    }
                }
        
                .MyAccountOverlay-SignUpButton {
                    @include narrow-desktop {
                        display: block;
                        margin: 0 auto;
                    }
        
                    @include ultra-narrow-desktop {
                        display: block;
                        margin: 0 auto;
                    }
        
                    @include narrow-tablet {
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
        
            &-BackLogin {
                margin-inline-start: 40px;
                text-decoration: underline;
                font-size: 1.6rem;
                font-weight: normal;
        
                @include mobile {
                    margin-inline-start: 0;
                    width: 100%;
                    display: block;
                    text-align: center;
                    margin-block-start: 10px;
                    font-size: 1.4rem;
                }
            }
        }
    }

    .Loader {
        margin: 0;
    }
}

