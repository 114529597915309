/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.GuestReturnsPage {
    h2 {
        margin-block-start: 30px;
        max-width: 60%;
    }

    &-Heading {
        margin-block-start: 30px;
        max-width: 60%;
        padding-inline-start: 20px;

        @include desktop-wide-1440 {
            max-width: 70%;
        }

        @include narrow-desktop {
            max-width: 70%;
        }

        @include ultra-narrow-desktop {
            max-width: 80%;
        }

        @include tablet {
            max-width: 80%;
        }

        @include mobile {
            max-width: 100%;
            padding-inline-start: 0;
        }

        h1 {
            margin-block-end: 20px;

            @include ultra-narrow-desktop {
                font-size: 2.4rem;
                margin-block-end: 10px;
            }
    
            @include tablet {
                font-size: 2.1rem;
                margin-block-end: 10px;
            }

            @include mobile {
                font-size: 2.4rem;
            }
        }

        h4 {
            @include ultra-narrow-desktop {
                font-size: 2rem;
            }
    
            @include tablet {
                font-size: 1.8rem;
            }

            @include mobile {
                font-size: 1.6rem;
                font-weight: 400;
            }
        }
    }

    &-Wrapper {
        display: flex;
        flex-direction: column;
        max-width: 77%;
        justify-content: center;
        margin: auto;

        @include ultra-narrow-desktop {
            max-width: 80%;
        }

        @include tablet {
            max-width: 70%;
        }

        @include mobile {
            max-width: 100%;
            padding-inline: 19px;
            padding-block-end: 40px;
            border-bottom: 1px solid #DEDEDE;
        }
    }
}
