/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-active-color: $default-neutral-base-color;
    --breadcrumbs-color: $default-neutral-light-color;
}

.Breadcrumbs {
    $crumb-padding: 11px;
    $arrow-size: 4px;

    font-family: $font-muli;

    @include desktop {
        margin-block-end: 50px;
    }

    @include desktop-wide-1440 {
        margin-block-end: 30px;
    }

    @include ultra-narrow-desktop {
        margin-block-end: 30px;
    }

    @include tablet {
        margin-block-end: 20px;
    }

    @include mobile {
        --breadcrumbs-background: #fff;

        display: block;
    }

    &-List {
        list-style: none;
        padding: 0;

        .Breadcrumb:first-child {
            .Breadcrumb-Link {
                font-weight: 700;                
            }
        }

        .ComingSoon {
            h2 {
                font-weight: 300;
                font-size: 4.2rem;
                margin: 0;
                line-height: 60px;

                @include mobile {
                    font-size: 2rem;
                    line-height: 20px;
                }
            }
        }
    }
}
