/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */
 .MyAccount-Heading {
    &:has(+ .AmastyViewReturn) {
      display: none;
    }
}

.AmastyViewReturn {
    color: #333333;
    font-style: normal;
    line-height: 1.42857143;
    font-size: 1.4rem;

    ._strong {
        font-weight: 600;
    }

    .amrma-main-container {
        width: 100%;
        margin: 0;
    }

    .stars-container {
        display: flex;
        flex-direction: row;
        margin-block-end: 20px;
    }

    .star {
        /* stylelint-disable-next-line declaration-no-important */
        border: none !important;
        align-self: baseline;
        height: 20px;
        cursor: pointer;
    }

    .hollow-star {
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzIDEuMjM2bDIuMTkyIDYuNzQ3YTIgMiAwIDAgMCAxLjkwMiAxLjM4Mmg3LjA5NGwtNS43MzkgNC4xN2EyIDIgMCAwIDAtLjcyNyAyLjIzNmwyLjE5MyA2Ljc0Ni01Ljc0LTQuMTdhMiAyIDAgMCAwLTIuMzUgMGwtNS43NCA0LjE3IDIuMTkyLTYuNzQ2YTIgMiAwIDAgMC0uNzI2LTIuMjM3bC01Ljc0LTQuMTdoNy4wOTVhMiAyIDAgMCAwIDEuOTAyLTEuMzgxTDEzIDEuMjM2eiIgc3Ryb2tlPSIjRkZDMTA3IiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=') center no-repeat;
    }

    .full-star {
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzIDEuMjM2bDIuMTkyIDYuNzQ3YTIgMiAwIDAgMCAxLjkwMiAxLjM4Mmg3LjA5NGwtNS43MzkgNC4xN2EyIDIgMCAwIDAtLjcyNyAyLjIzNmwyLjE5MyA2Ljc0Ni01Ljc0LTQuMTdhMiAyIDAgMCAwLTIuMzUgMGwtNS43NCA0LjE3IDIuMTkyLTYuNzQ2YTIgMiAwIDAgMC0uNzI2LTIuMjM3bC01Ljc0LTQuMTdoNy4wOTVhMiAyIDAgMCAwIDEuOTAyLTEuMzgxTDEzIDEuMjM2eiIgZmlsbD0iI0ZGQzEwNyIgc3Ryb2tlPSIjRkZDMTA3IiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=') center no-repeat;
    }

    .amrma-chat-container {
        box-sizing: border-box;
        background: #f9f9fc;
        border: 1px solid #ebeef5;
        border-radius: 2px;
    }

    .amrma-chat-block {
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        min-height: 270px;
        max-height: 270px;
        padding-inline-end: 5px;
        margin: 30px 20px 20px;

        @include mobile {
            min-height: 100px;
        }
    }

    .amrma-chat-block:hover {
        overflow-y: scroll;
        padding-inline-end: 0;
    }

    .amrma-chat-block::-webkit-scrollbar-track {
        border-radius: 3px;
    }

    .amrma-chat-block::-webkit-scrollbar {
        width: 5px;
    }

    .amrma-chat-block::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #c5c5c5;
    }

    .amrma-message-block {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 15px 20px 10px;
        border-radius: 10px;

        .amrma-message {
            white-space: pre-wrap;
            word-wrap: break-word;
        }

        .amrma-date {
            align-self: flex-end;
            margin: 0;
            padding: 0;
            color: #999;
            font-size: 12px;
        }

        .amrma-image {
            width: auto;
            height: auto;
            max-width: 225px;
            margin: 0;
            padding: 0;
        }
    }

    .amrma-message-block::before {
        position: absolute;
        inset-block-start: 15px;
        width: 11px;
        height: 22px;
        content: '';
    }

    .amrma-chat-message.-left {
        align-self: flex-start;

        .amrma-message-block {
            background: #e2f5fe;
        }

        .amrma-message-block::before {
            inset-inline-start: -11px;
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwLjUgMjEuNUwuMzI3IDMuMTlDLS41MzUgMS42MzUuOTA0LS4xODggMi42MTUuMjkxTDEwLjUgMi41djE5eiIgZmlsbD0iI0UxRjVGRSIvPjwvc3ZnPg==');
        }
    }

    .amrma-chat-message {
        margin: 5px 20px;
        max-width: 80%;

        .amrma-username {
            margin-block-end: 5px;
            color: #777;
        }

        .amrma-delete-container {
            text-align: end;
        }
    }

    .amrma-chat-message.-default {
        display: none;
    }

    .amrma-chat-message.-right {
        align-self: flex-end;

        .amrma-username {
            text-align: end;
        }

        .amrma-message-block {
            background: #eceeff;
        }

        .amrma-message-block::before {
            inset-inline-end: -11px;
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMjEuNUwxMC4xNzIgMy4xOWMuODYzLTEuNTU0LS41NzYtMy4zNzctMi4yODctMi44OThMMCAyLjV2MTl6IiBmaWxsPSIjRUNFRUZGIi8+PC9zdmc+');
        }
    }

    .amrma-message::before {
        position: absolute;
        width: 13px;
        height: 20px;
        content: '';
    }

    .amrma-chat-file.-newreturn {
        display: flex;
        align-items: center;
        margin: 0;

        .amrma-file {
            padding: 0;
        }

        .amrma-delete {
            padding: 0;
            margin-inline-start: 10px;
        }
    }

    .amrma-chat-file.-newreturn::before {
        inset-block-start: 7px;
    }

    ._nomargin {
        margin: 0;
    }

    .amrma-chat-file {
        position: relative;
        padding-inline-start: 25px;

        .amrma-file {
            font-weight: 600;
        }
    }

    .amrma-chat-file::before {
        position: absolute;
        inset-inline-start: 0;
        width: 17px;
        height: 16px;
        content: '';
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM5NiAxNmEzLjMzMiAzLjMzMiAwIDAgMS0yLjM5My0xLjAzNUEzLjU2NSAzLjU2NSAwIDAgMSAwIDEyLjQ5MmEzLjMxOCAzLjMxOCAwIDAgMSAuOTIyLTIuMzE4bC4xNC0uMTQ3TDkuNTU4IDEuMjVBNC4wNzkgNC4wNzkgMCAwIDEgMTIuNTExIDBjMS4xMzYtLjAwMSAyLjI4Ny40NjMgMy4xNjUgMS4zN0E0LjcwNyA0LjcwNyAwIDAgMSAxNyA0LjYzNmE0LjM2NCA0LjM2NCAwIDAgMS0xLjIxIDMuMDVsLTcuMTMyIDcuMzctLjUzMS41NDgtMS4wNi0xLjA5N0wxNC43MyA2LjU5Yy41MTMtLjUzLjc2OC0xLjIyNi43Ny0xLjk1M2EzLjEzIDMuMTMgMCAwIDAtLjg4Ni0yLjE3MSAyLjkzMiAyLjkzMiAwIDAgMC0yLjEwMy0uOTE1IDIuNTk5IDIuNTk5IDAgMCAwLTEuODkuNzk1TDEuOTg0IDExLjI3Yy0uMzIyLjMzMi0uNDguNzYzLS40ODIgMS4yMjIgMCAuNDg1LjE4My45ODYuNTYyIDEuMzc3LjM3OS4zOS44NjIuNTggMS4zMzIuNThhMS42MzUgMS42MzUgMCAwIDAgMS4xODQtLjQ5Nmw2LjUxNC02LjczYS42NTUuNjU1IDAgMCAwIC4xODItLjQ3LjguOCAwIDAgMC0uMjI5LS41NTYuNzQ1Ljc0NSAwIDAgMC0uNTM4LS4yMzYuNjIuNjIgMCAwIDAtLjQ1My4xODhsLTUuNTQgNS43MjQtMS4wNjItMS4wOTYgNS41NC01LjcyNGMuNDE2LS40MzIuOTctLjY0NiAxLjUxNS0uNjQ0LjU3OCAwIDEuMTYuMjM2IDEuNi42OTEuNDQuNDU2LjY3IDEuMDU2LjY3IDEuNjU0IDAgLjU2LS4yMDcgMS4xMzQtLjYyMyAxLjU2NEw1LjY0IDE1LjA0OUEzLjEwNCAzLjEwNCAwIDAgMSAzLjQwMiAxNmgtLjAwNnoiIGZpbGw9IiMxOTc5QzMiLz48L3N2Zz4=');
    }

    .amrma-chat-file.-preview {
        display: flex;
        align-items: center;
        margin: 25px;
        padding-inline-end: 20px;
        color: #006bb4;
    }


    .amrma-attach-file .amrma-file {
        margin: 5px 0;
        padding-inline-start: 20px;
        color: #1979c3;
    }

    .label {
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
    }

    .amrma-delete {
        display: inline-block;
        margin-inline-start: 10px;
        width: 16px;
        height: 16px;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzNiAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTM1LjkxOCAyLjcxNEwzMy4yMDMgMCAxOC40MTggMTQuNzg3IDMuNjMzIDAgLjkxOCAyLjcxMyAxNS43MDUgMTcuNS45MiAzMi4yODcgMy42MzMgMzVsMTQuNzg1LTE0Ljc4NiAxNC43ODUgMTQuNzg1IDIuNzExLTIuNzEzTDIxLjEzMSAxNy41IDM1LjkxOCAyLjcxNCIgZmlsbD0iIzAwNmJiNCIvPjwvc3ZnPg==');
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .amrma-chat-notification {
        display: flex;
        margin: 5px 0 5px 20px;

        .amrma-notification {
            margin-inline-end: 15px;
            font-weight: 600;
            font-size: 14px;
        }

        .amrma-date {
            line-height: 19px;
        }
    }

    .amrma-chat-comment {
        margin: 0 auto;
        padding: 125px 0;
        color: #999;
    }


    .amrma-chat-send {
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 2px;
        background: #fff;
        margin: 0 20px 20px;

        @include mobile {
            margin: 0 10px 10px;
        }

        .amrma-textarea,
        .amrma-comment-block .amrma-comment {
            font-size: 16px;
        }

        .amrma-attach-file {
            width: 29px;
            height: 33px;
            margin: 0;
        }

        .amrma-textarea {
            margin-inline-start: 25px;
            width: 86%;
            max-height: 250px;
            min-height: 40px;
            border: none;
            resize: none;
            font-size: 1.4rem;
            font-family: $font-base;

            @include mobile {
                margin-inline-start: 20px;
                padding: 10px 0;
            }
        }

        .amrma-hiddentext {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            white-space: pre-wrap;
        }
    }

    .amrma-attach-file {
        .amrma-label {
            position: relative;
            padding-inline-start: 20px;
            font-weight: bold;
            font-size: 16px;
            cursor: pointer;
            color: #1979c3;
            transition: color .3s ease;
        }

        .amrma-label:hover {
            text-decoration: underline;
            color: #2196f3;
        }

        .amrma-label::before {
            position: absolute;
            inset-block-start: 4px;
            inset-inline-start: 0;
            width: 17px;
            height: 16px;
            content: '';
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM5NiAxNmEzLjMzMiAzLjMzMiAwIDAgMS0yLjM5My0xLjAzNUEzLjU2NSAzLjU2NSAwIDAgMSAwIDEyLjQ5MmEzLjMxOCAzLjMxOCAwIDAgMSAuOTIyLTIuMzE4bC4xNC0uMTQ3TDkuNTU4IDEuMjVBNC4wNzkgNC4wNzkgMCAwIDEgMTIuNTExIDBjMS4xMzYtLjAwMSAyLjI4Ny40NjMgMy4xNjUgMS4zN0E0LjcwNyA0LjcwNyAwIDAgMSAxNyA0LjYzNmE0LjM2NCA0LjM2NCAwIDAgMS0xLjIxIDMuMDVsLTcuMTMyIDcuMzctLjUzMS41NDgtMS4wNi0xLjA5N0wxNC43MyA2LjU5Yy41MTMtLjUzLjc2OC0xLjIyNi43Ny0xLjk1M2EzLjEzIDMuMTMgMCAwIDAtLjg4Ni0yLjE3MSAyLjkzMiAyLjkzMiAwIDAgMC0yLjEwMy0uOTE1IDIuNTk5IDIuNTk5IDAgMCAwLTEuODkuNzk1TDEuOTg0IDExLjI3Yy0uMzIyLjMzMi0uNDguNzYzLS40ODIgMS4yMjIgMCAuNDg1LjE4My45ODYuNTYyIDEuMzc3LjM3OS4zOS44NjIuNTggMS4zMzIuNThhMS42MzUgMS42MzUgMCAwIDAgMS4xODQtLjQ5Nmw2LjUxNC02LjczYS42NTUuNjU1IDAgMCAwIC4xODItLjQ3LjguOCAwIDAgMC0uMjI5LS41NTYuNzQ1Ljc0NSAwIDAgMC0uNTM4LS4yMzYuNjIuNjIgMCAwIDAtLjQ1My4xODhsLTUuNTQgNS43MjQtMS4wNjItMS4wOTYgNS41NC01LjcyNGMuNDE2LS40MzIuOTctLjY0NiAxLjUxNS0uNjQ0LjU3OCAwIDEuMTYuMjM2IDEuNi42OTEuNDQuNDU2LjY3IDEuMDU2LjY3IDEuNjU0IDAgLjU2LS4yMDcgMS4xMzQtLjYyMyAxLjU2NEw1LjY0IDE1LjA0OUEzLjEwNCAzLjEwNCAwIDAgMSAzLjQwMiAxNmgtLjAwNnoiIGZpbGw9IiMxOTc5QzMiLz48L3N2Zz4=');
            
            @include mobile {
                inset-inline-start: 0;
                transform: scale(0.8);
            }
        }

        .amrma-attach {
            display: none;
        }
    }

    .amrma-chat-send .amrma-attach-file .amrma-label::before {
        inset-block-start: 0;
        inset-inline-start: 5px;
        width: 29px;
        height: 27px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01Ljc5MyAyN2MtMS40Ny4wMDItMi45NTMtLjU5Mi00LjA4My0xLjc0N0E1Ljk4MiA1Ljk4MiAwIDAgMSAwIDIxLjA4MWE1LjU2OCA1LjU2OCAwIDAgMSAxLjU3My0zLjkxMmwuMjQtLjI0OEwxNi4zMDQgMi4xMDlBNi45OTYgNi45OTYgMCAwIDEgMjEuMzQyIDBjMS45MzgtLjAwMiAzLjkwMi43OCA1LjM5OSAyLjMxQTcuODk4IDcuODk4IDAgMCAxIDI5IDcuODI1Yy4wMDMgMS44NjgtLjY4MyAzLjczOC0yLjA2NCA1LjE0OUwxNC43NyAyNS40MDlsLS45MDYuOTI1LTEuODEtMS44NTFMMjUuMTI4IDExLjEyYy44NzYtLjg5NSAxLjMxMS0yLjA3IDEuMzEzLTMuMjk3IDAtMS4yOTYtLjUtMi42My0xLjUxLTMuNjYzLTEuMDEzLTEuMDM1LTIuMzE2LTEuNTQzLTMuNTg4LTEuNTQ0YTQuNDU3IDQuNDU3IDAgMCAwLTMuMjI0IDEuMzQzTDMuMzg0IDE5LjAxOGMtLjU0OS41Ni0uODIgMS4yODctLjgyMiAyLjA2My4wMDIuODE4LjMxMyAxLjY2My45NiAyLjMyMy42NDUuNjU3IDEuNDcuOTc4IDIuMjcxLjk4YTIuODA0IDIuODA0IDAgMCAwIDIuMDItLjgzOWwxMS4xMTItMTEuMzU3Yy4yMDgtLjIxMy4zMS0uNDgyLjMxMS0uNzkxIDAtLjMyNi0uMTI0LS42NjYtLjM5MS0uOTRhMS4yNzggMS4yNzggMCAwIDAtLjkxOC0uMzk4IDEuMDYzIDEuMDYzIDAgMCAwLS43NzMuMzE4bC05LjQ1IDkuNjU4LTEuODEyLTEuODQ4IDkuNDUyLTkuNjZhMy41OCAzLjU4IDAgMCAxIDIuNTgzLTEuMDg2Yy45ODYgMCAxLjk3OC4zOTcgMi43MyAxLjE2NmEzLjk5OSAzLjk5OSAwIDAgMSAxLjE0MSAyLjc5IDMuNzcxIDMuNzcxIDAgMCAxLTEuMDYxIDIuNjRMOS42MiAyNS4zOTRBNS4zMjMgNS4zMjMgMCAwIDEgNS44MDMgMjdoLS4wMSIgZmlsbD0iIzE5NzlDMyIvPjwvc3ZnPg==');
    
        @include mobile {
            inset-inline-start: 0;
            transform: scale(0.8);
        }
    }

    .amrma-return-number {
        display: block;

        .amrma-header {
            padding-bottom: 10px;
            border-bottom: 1px solid #707070;
            color: #F26700;
            font-size: 4.2rem;
            font-weight: bold;
            margin-bottom: 40px;

            @include desktop-wide-1440 {
                font-size: 3.5rem;
            }
    
            @include narrow-desktop {
                font-size: var(--h1-font-size-narrow);
                line-height: var(--h1-line-height-narrow);
            }
    
            @include ultra-narrow-desktop {
                font-size: 2.6rem;
                margin-block-end: 40px;
            }
    
            @include tablet {
                font-size: 2.1rem;
                margin-block-end: 30px;
            }
    
            @include mobile {
                font-size: 2.6rem;
                padding-block-end: 0;
                border-bottom: none;
                margin-block-start: 30px;
            }
        }

        .amrma-status {
            align-self: flex-start;
            margin: 0 10px;
        }
    }


    .amrma-header-block {
        display: block;
    }

    .bottom-header-block {
        @include mobile {
            display: flex;
            flex-direction: column-reverse;
            margin-block-end: 30px;
        }
    }

    .amrma-action-block {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        @include mobile {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .amrma-cancel {
            color: #3A3939;
            background-color: #ffffff;
            font-size: 2.1rem;
            box-shadow: none;

            @include ultra-narrow-desktop {
                font-size: 1.6rem;
            }

            @include tablet {
                font-size: 1.4rem;
                height: 40px;
                border-radius: 14px;
            }

            @include mobile {
                font-size: 1.4rem;
                height: 40px;
                border-radius: 14px;
            }

            &:hover {
                color: #F26700;
                border: 1px solid #F26700;
            }
        }
    }   


    .amrma-bank-details,
    .amrma-return-instructions,
    .amrma-header-block {
        margin-block-end: 40px;

        @include tablet {
            margin-block-end: 30px; 
        }

        @include mobile {
            margin-block-end: 30px;
        }
    }

    .amrma-status {
        padding: 5px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
    }

    .amrma-status.-pending {
        background: #2196f3;
    }

    .amrma-status.-authorized {
        background: #3f51b5;
    }

    .amrma-status.-received {
        background: #9c27b0;
    }

    .amrma-status.-resolved {
        background: #4caf50;
    }

    .amrma-status.-cancelled {
        background: #9e9e9e;
    }

    .amrma-status.-rejected {
        background: #c33d3c;
    }

    .returns-collaps {
        margin-block-end: 55px;

        @include mobile {
            margin-bottom: 35px;
        }
    }

    .amrma-status-container {
        position: relative;
        box-sizing: content-box;
        margin-block-end: 100px;
        margin-block-start: 50px;

        @include tablet {
            margin-block-end: 70px;
            margin-block-start: 40px;
        }

        @include mobile {
            margin-block-end: 50px;
            margin-block-start: 5px;
        }

        .amrma-percent {
            position: absolute;
            z-index: 1;
            box-sizing: content-box;
            width: 0;
            height: 100%;
            border-bottom: 3px solid #FF8536;
        }

        .amrma-percent.-status-0 {
            width: 0;
        }

        .amrma-percent.-status-1 {
            width: 34%;
        }

        .amrma-percent.-status-2 {
            width: 67%;
        }

        .amrma-percent.-status-3,
        .amrma-percent.-status-4 {
            width: 100%;
        }

        .amrma-progress {
            position: absolute;
            width: 90%;
            height: 42%;
            border-bottom: 3px solid #DEDEDE;
            z-index: 0;
            left: 3%;

            @include mobile {
                left: 5%;
            }
        }


        .amrma-steps {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 90%;
            left: 3%;

            @include mobile {
                width: 100%;
                left: 4%;
            }
        }

        .amrma-step.-complete::before {
            position: absolute;
            content: var(--omino-resi);;
        }

        .amrma-step.-checked::before {
            position: absolute;
            content: var(--omino-resi);
            background-color: #fff;
            top: -15px;
            width: 50px;

            @include mobile {
                transform: scale(0.7);
                left: -5px;
            }
        }

        .amrma-name {
            display: none;
        }

        .amrma-step.-failed::before {
            content: '';
            inset-block-start: 3px;
            width: 20px;
            height: 20px;
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iMTAiIGZpbGw9IiNEODBGMzMiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE0LjA0IDcuMzczYTEgMSAwIDAgMC0xLjQxNC0xLjQxNEwxMCA4LjU4NSA3LjM3NCA1Ljk2QTEgMSAwIDEgMCA1Ljk2IDcuMzczTDguNTg2IDEwIDUuOTYgMTIuNjI2YTEgMSAwIDEgMCAxLjQxNCAxLjQxNEwxMCAxMS40MTRsMi42MjYgMi42MjZhMSAxIDAgMCAwIDEuNDE0LTEuNDE0bC0yLjYyNi0yLjYyNyAyLjYyNi0yLjYyNnoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=');
        }

        .amrma-step.-resolved .-checked .amrma-name,
        .amrma-step.-pending .-checked .amrma-name {
            max-width: 150px;
        }

        .amrma-step.-pending.-checked::before {
            border-radius: inherit;
            background-position: right;
        }

        .amrma-step {
            position: relative;
            min-width: 15px;
            height: 30px;

            @include mobile {
                min-width: 50px;
            }
        }

        .amrma-step::before {
            position: absolute;
            content: var(--omino-resi-disabled);
            background-color: #ffffff;
            top: -15px;
            width: 50px;
            
            @include mobile {
                transform: scale(0.7);
                left: -5px;
            }
        }

        .amrma-step.-authorized.-checked::before,
        .amrma-step.-received.-checked::before {
            inset-inline-start: 0;
        }

        .amrma-step.-resolved.-checked {
            display: flex;
            justify-content: flex-end;
            margin: 0;
            text-align: end;
        }

        .amrma-step.-resolved.-checked::before {
            inset-inline-end: 0;
            padding: 0 20px 0 0;
            border-radius: inherit;
        }

        .amrma-step.-resolved.-checked::before.-received.-checked::before {
            inset-inline-start: 30%;
        }

        .amrma-steps>.amrma-step::after {
            position: absolute;
            inset-inline-start: -29px;
            inset-block-start: 34px;
            min-width: 100px;
            content: attr(data-label);
            text-align: center;
            font-weight: bold;
            font-size: 1.6rem;
            font-family: $font-muli;
            color: #DEDEDE;

            @include mobile {
                font-size: 1.2rem;
                top: 28px;
                inset-inline-start: -36px;
            }
        }

        .amrma-steps>.amrma-step.-checked::after, .amrma-steps>.amrma-step.-complete::after {
            position: absolute;
            inset-inline-start: -29px;
            inset-block-start: 34px;
            min-width: 100px;
            content: attr(data-label);
            text-align: center;
            font-weight: bold;
            font-size: 1.6rem;
            font-family: $font-muli;
            color: #FF8536;

            @include mobile {
                font-size: 1.2rem;
                top: 28px;
                inset-inline-start: -36px;
            }
        }
    }

    .amrma-stars-container {
        box-sizing: border-box;
        margin: 20px auto;
        padding: 25px 15px 15px;
        max-width: 530px;
        border: 2px solid rgba(23, 93, 224, 0.3);
        border-radius: 4px;
        background: #fff;
        box-shadow: 0 4px 8px rgba(0, 62, 176, 0.1);

        .rated-message {
            text-align: center;
            font-weight: 600;
            font-size: 1.6rem;
            margin-block-end: 10px;
        }
    }

    .amrma-rating-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .amrma-title {
            line-height: 22px;
            font-weight: bold;
            font-size: 16px;
            color: #494949;
        }
    }

    .amrma-feedback-container {
        display: flex;
        box-sizing: border-box;
        margin: 20px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 2px;

        .amrma-feedback {
            margin-inline-end: 10px;
            width: 80%;
            height: 40px;
            border: none;
        }
    }

    .amrma-customer-information {
        display: flex;
        margin-block-end: 70px;

        @include ultra-narrow-desktop {
            margin-block-end: 50px;
        }

        @include tablet {
            margin-block-end: 50px;
        }

        @include mobile {
            margin-block-end: 30px;
            flex-direction: column;
        }

        .amrma-customer-name {
            margin-block-end: 20px;
        }
    }

    .amrma-customer-name,
    .amrma-customer-address {
        flex-basis: 50%;
    }

    .amrma-instructions {
        display: none;
    }

    .amrma-instruction-block {
        display: none;
    }

    .amrma-instructions:checked~.amrma-instruction-block {
        display: block;
        padding: 20px 10px 15px;
        border-radius: 1px 1px 2px 2px;
        background: #eaf1ff;
    }

    .amrma-instructions-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #1e79c2;
        border-radius: 2px;
        cursor: pointer;

        .amrma-title {
            margin-block-end: 15px;
        }

        .amrma-show {
            position: relative;
            width: 30px;
            height: 30px;
            box-sizing: border-box;
            border: 2px solid #1979c3;
            border-radius: 50%;
        }

        .amrma-show::before {
            position: absolute;
            width: 10px;
            inset-block-start: 18%;
            inset-inline-start: 28%;
            height: 10px;
            border-left: 1px solid #1979c3;
            border-top: 1px solid #1979c3;
            content: '';
            transform: rotate(225deg);
        }
    }

    .amrma-instructions:checked+.amrma-instructions-title .amrma-show::before {
        inset-block-start: 38%;
        transform: rotate(45deg);
    }

    .amrma-table-container {
        padding-inline-start: 10px;
        padding-block-start: 10px;
        border-block-start: 1px solid #707070;

        h2 {
            margin: 0;
            margin-block-end: 25px;
        }
    }

    .amrma-table-container .table-wrapper>.amrma-table .amrma-tbody>.amrma-row {
        >.col:not(:first-child):not(:last-child) {
            padding: 10px;
        }

        >.col:not(:first-child) {
            padding: 10px;
        }

        >.col.qty {
            padding-block-start: 10px;
        }

        >.col {
            border-top: 1px solid #d1d1d1;
        }

        >.col:first-child {
            position: relative;
            inset-block-start: auto;
            inset-inline-start: auto;
            padding: 10px 10px 10px 25px;
        }
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;

        >caption {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }


        .col.details {
            width: 45%;
            position: relative;
        }

        .col.amrma-details {
            width: 30%;
            position: relative;
        }

        .col.amrma-details .amrma-product-information {
            position: absolute;
            inset-block-start: 30%;
        }

        thead th {
            background-color: white;
        }
    }

    .amrma-product-name {
        font-size: 1.4rem;
    }

    .amrma-chat-title {
        margin-block-start: 60px;
        font-size: 2.6rem;

        @include mobile {
            margin-block-start: 30px;
            font-size: 2rem;
            margin-block-end: 10px;
        }
    }

    .ReturnShippingMode  {
        width: 100%;
        height: 120px;
        background-color: #F6F6F6;
        padding-block-start: 10px;
        padding-inline-start: 20px;
        margin-block-start: 20px;

        @include tablet {
            padding-block-start: 4px;
            margin-block-start: 10px;
        }

        @include mobile {
            padding-block-start: 4px;
            margin-block-start: 10px;
        }

        
        h4 {
            @include tablet {
                font-size: 1.9rem;
            }

            @include mobile {
                font-size: 1.6rem;
            }
        }
    }

    .amrma-send {
        min-width: 200px;

        @include mobile {
            min-width: 70px;
            max-width: 50px;
            box-shadow: none;
        }
    }

    .amrma-shipping-label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        @include mobile {
            flex-direction: column;
        }

        p {
            font-size: 2.1rem;
            font-family: $font-muli;
        }

        .link-shipping-label {
            width: 100%;
            max-width: 277px;
            border: 1px solid #3A3939;
            font-size: 2.1rem;
            font-family: $font-muli;
            color: #3A3939;
            text-align: end;
            border-radius: 20px;
            height: 50px;
            padding-block: 8px;
            font-weight: normal;
            padding-inline: 27px;
            
            &::before {
                position: absolute;
                content: var(--stampante);
                background-color: #fff;
                left: 19px;
                top: 11px;
            }

            &:hover {
                border: 1px solid #F26700;
                color: #F26700;
            }
        }
    }
}

.ProductInfoTable {
    margin-block-end: 45px;

    &-Container {
        display: flex;
        flex-direction: column;

        h3 {
            color: #3A3939;
            font-weight: 500;
            border-top: 1px solid #707070;
            padding-block-start: 10px;
            margin-block-start: 0;

            @include tablet {
                font-size: 2rem;
            }

            @include mobile {
                font-size: 2rem;
                border-top: none;
                padding-block-start: 0;
                margin-block-end: 10px;
            }
        }

        h4.returnLabel {
            @include mobile {
                margin-block-start: 3px;
                margin-block-end: 8px;
            }
        }

        .hiddenDesktop {
            @include desktop {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }
    }

    &-TableContainer {
        display: flex;
        flex-direction: column;
    }
 
    &-HeadTable {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include mobile {
            display: none;
        }
    }

    &-ProductRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding-block: 20px;
        border-block-end: 1px solid #707070;

        @include mobile {
            flex-direction: column;
            justify-content: flex-start;
        }

        &:first-child {
            padding-block-start: 10px;
        }

        &:last-child {
            border-block-end: none;
        }

        .ProductInfoTable-Element {
            &:not(:first-child) {
                padding-block-start: 10px;

                @include mobile {
                    padding-block-start: 3px;
                    padding-inline-start: 107px;
                }
            }
        }

        .returnLabel {
            @include desktop {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }

        
        h4.returnLabel {
            @include mobile {
                margin-block-start: 3px;
                margin-block-end: 8px;
            }
        }

        .hiddenDesktop {
            @include desktop {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }
    }

    &-Element {
        color: #000000;
    }

    &-ProductNameRow {
        display: flex;
        flex-direction: row;
        width: 30%;

        @include desktop-wide-1440 {
            width: 40%;
        }

        @include narrow-desktop {
            width: 40%;
        }

        @include tablet {
            width: 40%;
        }

        @include mobile {
            width: 100%;
        }

        .image {
            margin-inline-end: 25px;

            @include ultra-narrow-desktop {
                margin-inline-end: 16px;
            }
    
            @include tablet {
                margin-inline-end: 15px;
            }

            @include mobile {
                margin-inline-end: 25px;
            }

            .MyAccountOrderItemsTableRow-Picture {
                margin-top: 0;
                width: 82px;
                height: 82px;
                border: 1px solid #707070;
                padding-bottom: 82px;
                margin-inline-end: 0;
            }
        }

        .info {
            h5 {
                font-size: 2rem;
                font-weight: 600;
                text-transform: uppercase;
                margin-block-end: 2px;
                margin-block-start: 0;

                @include ultra-narrow-desktop {
                    font-size: 1.7rem;
                    margin-block-start: 7px;
                }
        
                @include tablet {
                    font-size: 1.6rem;
                }

                @include mobile {
                    font-size: 1.8rem;
                    margin-block-start: 10px;
                }
            }

            p {
                color: #3A3939;
                font-size: 1.4rem;
                max-width: 70%;

                @include mobile {
                    max-width: 95%;
                }
            }
        }
    }

    &-ProductSkuRow {
        width: 10%;

        @include mobile {
            width: 100%;

            h5.returnLabel {
                display: inline-block;
                margin-inline-end: 9px;
            }
        }
    }

    &-ProductQtyRow {
        width: 10%;
        text-align: center;

        @include mobile {
            width: 100%;
            text-align: start;

            .returnLabel {
                display: inline-block;
                margin-block-end: 12px;
            }
        }
    }

    &-ProductLabelRow {
        width: 18%;
        text-align: center;

        @include mobile {
            width: 100%;
            text-align: start;
        }
    }

    &-Element {
        @include mobile {
            width: 100%;
            text-align: start;
        }

        h5 {
            font-size: 1.6rem;
            font-weight: 600;

            @include tablet {
                font-size: 1.4rem;
            }

            @include mobile {
                font-size: 1.4rem;

                &.returnLabel {
                    font-size: 1.6rem;
                    margin-block-start: 0;
                    margin-block-end: 3px;
                }
            }
        }
    }
}
