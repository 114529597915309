/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

.Field {
    &_type {
        &_numberWithControls {
            [type="number"] {
                appearance: textfield;
                color: var(--color-black);
                font-family: $font-muli;
                font-size: 1.6rem;
                font-weight: 500;
                width: 70%;
                max-width: 28px;
                height: 28px;
                border: none;
                padding: 0;
                order: 2;
                text-align: center;
                margin: 0 8px;
                pointer-events: none;

                @include ultra-narrow-desktop {
                    max-width: 80px;
                    margin: 0 5px;
                }

                @include tablet {
                    max-width: 80px;
                    margin: 0 5px;
                }

                &:focus {
                    border: none;
                }

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    appearance: none;
                    margin: 0;
                }

                ~ button {
                    background: transparent;
                    border: none;
                    border-radius: 10px;
                    color: $default-neutral-base-color;
                    inset-inline-start: 0;
                    line-height: 0;
                    margin: 0;
                    order: 1;
                    display: flex;
                    width: 32px;
                    height: 32px;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    inset-block-start: 0;

                    @include ultra-narrow-desktop {
                        width: 29px;
                        height: 29px;
                    }

                    @include tablet {
                        width: 29px;
                        height: 29px;
                    }

                    @include mobile {
                        width: 36px;
                        height: 36px;
                    }

                    svg {
                        transform: scale(0.5);
                        fill: $default-neutral-base-color;
                    }

                    @include hoverable {
                        &:hover {
                            border-color: var(--primary-dark-color);

                            svg {
                                fill: $default-neutral-base-color;
                            }
                        }
                    }

                    &:active {
                        border-color: var(--primary-dark-color);

                        svg {
                            fill: $default-neutral-base-color;
                        }
                    }

                    &:first-of-type {
                        inset-inline-start: 0;
                        order: 3;
                    }

                    &:last-of-type {
                        inset-block-end: 0;
                        inset-inline-end: 0;
                        order: 1;
                    }

                    &:disabled {
                        opacity: 0.35;
                        cursor: default;
                        pointer-events: none;
                        background-color: #F5F5F5;
                    }

                    .AddIcon_isPrimary {
                        fill: $default-neutral-base-color;
                        opacity: 1;
                    }

                    span {
                        @include mobile {
                            inset-block-start: -2px;
                        }
                    }
                }
            }
        }
    }
}
