/** extend sidea */
/* stylelint-disable declaration-no-important */

.banner {
    position: relative;
    margin-block-start: 120px;

    @include tablet {
        min-height: unset !important;
    }

    @include mobile {
        margin-block-start: 45px;
        min-height: 220px !important;
        width: 100vw;
        padding: 0 0px;
        margin-inline: -20px;
    }

    &.container {
        @include mobile {
            width: 100vw;
            padding-inline: 0;
        }
    }

    .evidenza h2 {
        font-weight: 300;

        @include mobile {
            line-height: 1.2;
            margin-block-start: 29px;
            margin-block-end: 10px;

            strong {
                display: block;
            }
        }
    }

    @include mobile {
        .p18, .p18 p {
            font-size: 12px;
            line-height: 1.3;
        }

        p {
            margin-block-end: 0;
        }
    }

    &.promo {
        @include mobile {
            margin-block-end: 40px;
        }
        
        .pagebuilder-column-group {
            display: flex;
            gap: 10px;

            @include mobile {
                flex-direction: column;
                gap: 0;
            }

            .pagebuilder-column {
                @include mobile {
                    width: 100% !important;
                }

                figure {
                    @include mobile {
                        height: 300px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        img {
                            width: auto;
                        }
                    }
                }

                &:first-child {
                    @include mobile {
                        display: none !important;
                    }
                }

                &:last-child {
                    div {
                        h2 {
                            font-size: 4.2rem;

                            @include desktop-wide-1600 {
                                margin-block-start: 0;
                                margin-block-end: 10px;
                            }

                            @include desktop-wide-1440 {
                                font-size: 3.6rem;
                            }

                            @include narrow-desktop {
                                font-size: 3rem;
                                margin-block-start: -15px;
                            }

                            @include ultra-narrow-desktop {
                                font-size: 2.6rem;
                                margin-block-start: -35px;
                                margin-block-end: 0px;
                            }

                            @include tablet {
                                font-size: 2.3rem;
                                margin-block-start: -35px;
                                margin-block-end: 0px;
                            }

                            @include mobile {
                                font-size: 3rem;
                                line-height: 1.1;
                                margin-block-start: -20px;
                                margin-block-end: 10px;
                            }
                        }

                        h3 {
                            @include desktop-wide-1440 {
                                font-size: 2.1rem;
                            }

                            @include narrow-desktop {
                                font-size: 1.9rem;
                            }

                            @include ultra-narrow-desktop {
                                font-size: 1.9rem;
                            }

                            @include tablet {
                                font-size: 1.6rem;
                            }

                            @include mobile {
                                font-size: 1.5rem;
                                max-width: 205px;
                                margin: 0 auto;
                            }
                        }

                        h2, h3 {
                            color: $white;
                        }
                    }

                    .call-to-action {
                        position: absolute;
                        z-index: 10;
                        inset-block-start: 99px;
                        inset-inline: 0;
                    }

                    .button-container {
                        position: absolute;
                        inset-block-end: 70px;
                        inset-inline: 0;

                        @include desktop-wide-1600 {
                            inset-block-end: 50px;
                        }

                        @include narrow-desktop {
                            inset-block-end: 30px;
                        }

                        @include ultra-narrow-desktop {
                            inset-block-end: 30px;
                        }

                        @include tablet {
                            inset-block-end: 30px;
                        }

                        @include mobile {
                            inset-block-end: 30px;
                        }

                        .pagebuilder-button-primary {
                            background-color: $white;
                            border-color: $white;
                            color: $black;
                            box-shadow: 8px 9px $black;

                            @include ultra-narrow-desktop {
                                max-width: 210px;
                                min-width: 210px;
                                height: 40px;
                                line-height: 30px;
                            }
    
                            @include tablet {
                                max-width: 190px;
                                min-width: 190px;
                                height: 40px;
                                line-height: 30px;
                            }

                            @include mobile {
                                max-width: 140px;
                                min-width: 140px;
                                background-color: $default-primary-dark-color;
                                border-color: $default-primary-dark-color;
                                color: $white;
                            }

                            &:hover {
                                background-color: $default-primary-dark-color;
                                border-color: $default-primary-dark-color;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    &.collect {
        .pagebuilder-column-group {
            display: flex;
            gap: 10px;

            @include mobile {
                flex-direction: column;
                gap: 0;
            }

            .pagebuilder-column {
                @include mobile {
                    width: 100% !important;
                }

                &:first-child {
                    @include mobile {
                        display: none !important;
                    }
                }

                &:last-child {
                    div {
                        position: absolute;
                        z-index: 10;
                        inset-inline-start: 0;
                        inset-inline-end: 0;
                        inset-block-start: 38px;
                        color: $white;

                        @include mobile {
                            inset-block-start: 20px;
                        }

                        h2 {
                            font-size: 4.2rem;

                            @include desktop-wide-1600 {
                                margin-block-start: 0;
                                margin-block-end: 10px;
                            }

                            @include desktop-wide-1440 {
                                font-size: 3.6rem;
                            }

                            @include ultra-narrow-desktop {
                                margin-block-start: -15px;
                                margin-block-end: 0;
                                font-size: 3.2rem;
                            }

                            @include tablet {
                                margin-block-start: -15px;
                                margin-block-end: 3px;
                                font-size: 2.8rem;
                            }

                            @include mobile {
                                font-size: 3rem;
                                margin-block-end: 4px;
                            }
                        }

                        h3 {
                            @include desktop-wide-1440 {
                                font-size: 2.1rem;
                            }

                            @include ultra-narrow-desktop {
                                font-size: 1.8rem;
                            }

                            @include tablet {
                                font-size: 1.6rem;
                            }

                            @include mobile {
                                font-size: 1.5rem;
                                line-height: 1.3;
                                max-width: 220px;
                                margin: 0 auto;
                            }
                        }
                        
                        h2, h3 {
                            color: $white;
                        }
                    }

                    figure {
                        @include mobile {
                            display: flex;
                            justify-content: center;
                        }

                        img {
                            &.pagebuilder-mobile-only {
                                @include mobile {
                                    height: 310px;
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

