/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 * Override Sidea */

:root {
    --loader-scale: 1;
}

.Loader {
    background-color: rgba(255, 255, 255, .6);
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    z-index: 75;
    margin: auto;
    opacity: 0;
    animation: appear 1ms 200ms forwards;

    &-Scale {
        position: absolute;
        transform: translate(-50%, -50%) scale(var(--loader-scale));
        inset-inline-start: 50%;
        inset-block-start: calc(50% - (3px * var(--loader-scale)));

        &:dir(rtl) {
            transform: translate(50%, -50%) scale(var(--loader-scale));
        }
    }

    &-Main {
        // svg {
        // opacity: 0.5;
        // animation: loading-bar 1s infinite 1s;
        // }
        
        @keyframes loading-bar {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 0.15;
            }

            100% {
                opacity: 0.3;
            } 
        }

        @keyframes loading-bar2 {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 0.25;
            }

            100% {
                opacity: 0.5;
            } 
        }

        @keyframes loading-bar3 {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 0.5;
            }

            100% {
                opacity: 1;
            } 
        }
                
        svg:nth-child(1) {
            opacity: 0.3;
            animation: loading-bar 1s infinite 0.2s;
        }
        
        svg:nth-child(2) {
            opacity: 0.5;
            inset-inline-end: 10px;
            animation: loading-bar2 1s infinite 0.2s;
        }

        svg:nth-child(3) {
            opacity: 1;
            inset-inline-end: 20px;
            animation: loading-bar3 1s infinite 0.2s;
        }
    }    
}
