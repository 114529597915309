/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.ReturnsCollapse {
    background-color: #f6f6f6;
    width: 100%;
    max-width: 985px;
    margin-block-start: 20px;
    padding-inline: 25px;
    padding-block: 7px;

    .text-return {
        padding-block-end: 50px;

        @include mobile {
            padding-block-start: 20px;
            padding-block-end: 30px;
        }

        p {
            font-size: 1.4rem;
            padding-inline-end: 100px;

            @include tablet {
                padding-inline-end: 30px;
            }

            @include mobile {
                padding-inline-end: 0;
            }
        }
    }

    &-Button {
        button {
            width: 100%;
        }
    }

    &-Header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            font-size: 2.1rem;
            font-weight: 600;
            color: #3A3939;
            margin: 0;
            margin-block-end: 0;

            @include tablet {
                font-size: 1.8rem;
            }

            @include mobile {
                font-size: 1.6rem;
            }
        }
    }

    &-Icon {
        p {
            display: none;
        }

        &::before {
            content: '+'; 
            font-size: 3.3rem;
            font-weight: 500;
        }
        
        &_isCollapsed {
            &::before {
                content: '-';
            }
        }
    }
}
